import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Typography from '@mui/material/Typography';
import SearchBox from '@geomatico/geocomponents/SearchBox';
import {CircularProgress} from '@mui/material';
import Box from '@mui/material/Box';

export type SearchProps = {
  text: string,
  resultTotal: number,
  onTextChange: (_text: string)=> void,
  onSearchClick: ()=> void,
  isFilteredResults: boolean,
  isWaiting: boolean
};
const Search: FC<SearchProps> = ({text, resultTotal, onTextChange, onSearchClick, isFilteredResults, isWaiting}) => {

  const {t} = useTranslation();

  return (
    <>
      {!isWaiting &&
        <Typography
          variant='caption'
          gutterBottom
          sx={{fontStyle: 'italic'}}
        >
          {resultTotal} {isFilteredResults ? t('filteredCases') : t('totalCases')}
        </Typography>
      }
      <Box display='flex' alignItems='center'>
        <SearchBox
          text={text}
          onTextChange={onTextChange}
          onSearchClick={onSearchClick}
          placeholder={t('search')}
          dense={true}
        />
        {isWaiting &&
          <CircularProgress size={15} sx={{ml: 2, position: 'absolute', right: '64px'}}/>
        }
      </Box>

    </>
  );
};

export default Search;
