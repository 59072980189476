import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import es from './i18n/es.json';
import en from './i18n/en.json';
import fr from './i18n/fr.json';
import it from './i18n/it.json';
import tr from './i18n/tr.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['querystring', 'localStorage', 'navigator'],
      lookupLocalStorage: 'i18nextLng',
      lookupQuerystring: 'translate',
      caches: ['localStorage'],
      checkWhitelist: true
    },
    resources: {
      en: {
        translation: en
      },
      es: {
        translation: es
      },
      fr: {
        translation: fr
      },
      it: {
        translation: it
      },
      tr: {
        translation: tr
      },
    },
    load: 'languageOnly',
    whitelist: ['es', 'en', 'fr', 'it', 'tr'],
    fallbackLng: 'en',
    debug: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    useSuspense: true,
  });
