import React, {FC, useState} from 'react';
import {AccordionComponent} from './conflict-detail/components/AccordionComponent';
import {BrowseByEntity} from './BrowseByEntity';
import {useTranslation} from 'react-i18next';
import useFetch from '@geomatico/geocomponents/hooks/useFetch';
import {API_BROWSE_MAPS} from '../config';

const stylesAccordion = {
  '& .MuiTypography-root': {fontSize: '12px'},
  '& .MuiAccordionSummary-root': {background: 'white',}
};

export const BrowseMapsFilter: FC = () => {
  const {t} = useTranslation();

  const [expanded, setExpanded] = useState<Array<string>>([]);

  const {data} = useFetch(API_BROWSE_MAPS);

  const handleAccordionExpanded = (panel: string) => {
    let newExpandedPanels = [];
    if (expanded.includes(panel)) {
      newExpandedPanels = [...expanded].filter(p => p !== panel);
    } else {
      newExpandedPanels = [...expanded, panel];
    }
    setExpanded(newExpandedPanels);
  };

  return <>
    <AccordionComponent sx={stylesAccordion} id={'country'} title={t('country')} expanded={expanded} onExpandedChange={handleAccordionExpanded}>
      {data?.country && <BrowseByEntity entityName={'country'} data={data.country}/>}
    </AccordionComponent>
    <AccordionComponent sx={stylesAccordion} id={'commodity'} title={t('commodity')} expanded={expanded} onExpandedChange={handleAccordionExpanded}>
      {data?.commodity && <BrowseByEntity entityName={'commodity'} data={data.commodity}/>}
    </AccordionComponent>
    <AccordionComponent sx={stylesAccordion} id={'company'} title={t('company')} expanded={expanded} onExpandedChange={handleAccordionExpanded}>
      {data?.company && <BrowseByEntity entityName={'company'} data={data.company}/>}
    </AccordionComponent>
    <AccordionComponent sx={stylesAccordion} id={'type'} title={t('type')} expanded={expanded} onExpandedChange={handleAccordionExpanded}>
      {data?.type && <BrowseByEntity entityName={'type'} data={data.type}/>}
    </AccordionComponent>
  </>;
};
