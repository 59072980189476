import React, {FC} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {useTranslation} from 'react-i18next';
import {generateSection} from '../ConflictDetail';
import {Conflict} from '../../../commonTypes';

export type ConflictMobilizationProps = {
  mobilization: Conflict['mobilization']
}

export const ConflictMobilization: FC<ConflictMobilizationProps> = ({mobilization}) => {
  const {t} = useTranslation();
  const {intensity, groups_mobilizing, forms_mobilizing, reaction_stage, other_mobilizing_forms, other_mobilizing_groups} = mobilization;

  return <Box>
    {generateSection(t('conflictIntensity'), intensity?.name)}
    {generateSection(t('conflictReactionStage'), reaction_stage?.name)}
    <Typography variant="h6" gutterBottom>
      {t('mobilizing')}
    </Typography>
    <Typography variant="body2" component="div" gutterBottom>
      {groups_mobilizing.map(({name}) => <div key={name}>- {name}</div>)}
    </Typography>
    <Divider sx={{mb: 3}}/>
    {generateSection(t('conflictOtherMobilizingGroups'), other_mobilizing_groups)}
    <Typography variant="h6" gutterBottom>
      {t('conflictFormsMobilization')}
    </Typography>
    <Typography variant="body2" component="div" gutterBottom>
      {forms_mobilizing.map(({name}) => <div key={name}>- {name}</div>)}
    </Typography>
    <Divider sx={{mb: 3}}/>
    {generateSection(t('conflictOtherMobilizingForms'), other_mobilizing_forms, false)}
  </Box>;
};
