import React, {FC} from 'react';
import GeoJSON, {Feature} from 'geojson';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import PopUpContent from './PopUpContent';
import {SxProps} from '@mui/system';
import styled from '@mui/styles/styled';
import {Popup} from 'react-map-gl';
import useMediaQuery from '@mui/material/useMediaQuery';
import {POP_UP_WIDTH_MOBILE, POP_UP_WIDTH_WIDESCREEN, SM_BREAKPOINT} from '../config';

const closeIconStyles: SxProps = {
  position: 'absolute',
  right: '-8px',
  top: '-8px',
  background: 'white',
  display: 'flex',
  borderRadius: '23px',
  justifyContent: 'center',
  cursor: 'pointer'
};

const PopupInfo = styled(Popup)({
  cursor: 'default',
  position: 'relative',
  '& .mapboxgl-popup-content': {
    padding: 0
  }
});
export type PopUpProps = {
  feature: Feature | undefined,
  onFeatureChange: (_feature: GeoJSON.Feature<GeoJSON.Point> | undefined)=> void
};

export const PopUp: FC<PopUpProps> = ({feature, onFeatureChange}) => {
  const widescreen = useMediaQuery(`@media (min-width:${SM_BREAKPOINT}px)`, {noSsr: true});

  return <>
    {feature &&
      <PopupInfo
        style={{maxWidth: widescreen ? POP_UP_WIDTH_WIDESCREEN: POP_UP_WIDTH_MOBILE}}
        longitude={feature.properties?.lng}
        latitude={feature.properties?.lat}
        closeButton={false}
        onClose={() => onFeatureChange(undefined)}
      >
        <Box onClick={() => onFeatureChange(undefined)} sx={closeIconStyles} position='absolute'><CloseIcon
          sx={{fontSize: '1rem'}}/></Box>
        <PopUpContent selectedFeature={feature}/>
      </PopupInfo>
    }
  </>;
};
