import React, {FC, useEffect, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {BASE_URL_API, filters, HEADER_HEIGHT, LIMIT_CONFLICT_RESULTS} from '../../config';
import Search from './Search';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {AccordionComponent} from '../conflict-detail/components/AccordionComponent';
import {BrowseMapsFilter} from '../BrowseMapsFilter';
import {Loader} from '../Loader';
import AdvancedSearch, {FilterProcessed} from '../advanced-search/AdvancedSearch';
import {ListConflictsCard} from './ListConflictsCard';
import {useUserSession} from '../../hooks/useUserSession';
import {ListFeaturedMapCard} from './ListFeaturedMapsCard';

const beautifySlug = (slug: string | undefined) => {
  if (slug) return slug.split('-').join(' ');
};

type ConflictListProps = {
  onFilterPointByIdChange: (_id: number | null) => void,
  onFilterByIds: (_conflictsIdsToFilter: Array<number> | null) => void
}

export const ConflictList: FC<ConflictListProps> = ({children, onFilterPointByIdChange, onFilterByIds}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {token} = useUserSession();
  const {filter, slug, filterUUID} = useParams();
  const [openBrowseMaps, setOpenBrowseMaps] = useState<Array<string>>(filterUUID ? ['filters', 'recentlyUpdatedCases'] : ['recentlyUpdatedCases']);

  // si en la url se añade algun filtro que no está contemplado en la API, redirecciono
  if (filter && !filters.includes(filter)) navigate('/');

  const isFilter = slug;

  const limit = LIMIT_CONFLICT_RESULTS;
  const [offset, setOffset] = useState<number>(0);
  const [results, setResults] = useState([]);
  const [searchText, setSearchText] = useState<string>('');
  const [maxResults, setMaxResults] = useState<number>(0);
  const [isWaiting, setIsWaiting] = useState(false);
  const [advancedFilters, setAdvancedFilters] = useState<Array<FilterProcessed> | null>(null);
  const [resultsAdvancedFilter, setResultsAdvancedFilter] = useState([]);

  useEffect(() => {
    if (!advancedFilters && !filterUUID) {
      return fetchData(0);
    } else if (advancedFilters) {
      fetchAdvancedFilters(0);
    }
  }, [filter, advancedFilters]);

  const fetchAdvancedFilters = (offset: number) => {
    if (offset === 0) setIsWaiting(true);
    const options = {
      body: JSON.stringify(advancedFilters),
      headers: {'X-CSRFToken': token, 'Accept': 'application/json', 'Content-Type': 'application/json'},
      method: 'POST',
    };
    fetch(`${BASE_URL_API}/conflicts/filter/?limit=${limit}&offset=${offset}`, options)
      .then(response => response.json())
      .then(data => {
        if (offset === 0) setIsWaiting(false);
        if (results.length) setResults([]);
        setResultsAdvancedFilter(offset === 0 ? data.results : resultsAdvancedFilter.concat(data.results));
        setAfterFetch(data.ids, data.count, offset + limit);
      });
  };

  const fetchData = (offset: number, loader = false) => {
    const url = `${BASE_URL_API}/${filter || 'conflicts'}/?search=${isFilter ? slug : searchText}&limit=${limit}&offset=${offset}`;
    if (loader) setIsWaiting(true);
    fetch(url)
      .then(res => res.json())
      .then(res => {
        if (loader) setIsWaiting(false);
        setResults(offset === 0 ? res.results : results.concat(res.results));
        setAfterFetch(res.ids || null, res.count, offset + limit);
      })
      .catch(() => setResults([]));
  };

  const setAfterFetch = (filterIds: Array<number> | null, maxResults: number, offset: number) => {
    onFilterByIds(filterIds);
    setMaxResults(maxResults);
    setOffset(offset);
  };

  const handleSearch = () => {
    fetchData(0, true);
  };

  const handleOpenBrowseMaps = (expansionPanelId: string) => {
    setOpenBrowseMaps(openBrowseMaps.includes(expansionPanelId) ? ['recentlyUpdatedCases'] : [expansionPanelId, 'recentlyUpdatedCases']);
  };

  const renderResults = () => (
    <Box id="conflict-list">

      <InfiniteScroll
        dataLength={advancedFilters ? resultsAdvancedFilter.length : results.length}
        next={advancedFilters ? () => fetchAdvancedFilters(offset) : () => fetchData(offset)}
        hasMore={advancedFilters ? resultsAdvancedFilter.length < maxResults : results.length < maxResults}
        loader={<Loader isVisible={true}/>}
        height={`calc(100vh - ${HEADER_HEIGHT}px)`}
        style={{overflowX: 'hidden'}}
      >

        <Box sx={{m: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <Typography variant="h5" sx={{width: '90%', fontWeight: 'bold'}}>
            {isFilter
              ? beautifySlug(slug)
              : 'EJAtlas - Global Atlas of Environmental Justice'
            }
          </Typography>
        </Box>

        <Box my={1} mx={3}>
          {!isFilter
            ? <Box mb={3} mt={2}>
              <Search
                text={searchText}
                resultTotal={maxResults}
                onTextChange={setSearchText}
                onSearchClick={handleSearch}
                isFilteredResults={!!advancedFilters || !!searchText}
                isWaiting={isWaiting}
              />
            </Box>
            : <Typography
              variant='caption'
              gutterBottom
              sx={{fontStyle: 'italic'}}>{maxResults} {t('totalCases')}</Typography>
          }
          <Typography variant="caption" sx={{mt: 4}}>{t('presentation')}</Typography>
        </Box>

        <AccordionComponent
          id={'filters'}
          title={t('filters')}
          expanded={openBrowseMaps}
          onExpandedChange={handleOpenBrowseMaps}
          sx={{'& .MuiAccordionDetails-root ': {p: 0}, '.MuiPaper-root': {top: 0}}}
        >
          {openBrowseMaps.includes('filters') &&
            <AdvancedSearch maxResults={maxResults} advancedFilters={advancedFilters} onAdvancedFiltersChange={setAdvancedFilters} isOpen={true} isWaiting={isWaiting}/>
          }
        </AccordionComponent>

        {!slug &&
          <>
            <AccordionComponent
              id={'legend'}
              title={t('legend')}
              expanded={openBrowseMaps}
              onExpandedChange={handleOpenBrowseMaps}
              sx={{'& .MuiAccordionDetails-root ': {p: 0}, '.MuiPaper-root': {top: 0}}}>
              {openBrowseMaps.includes('legend') &&
                children
              }
            </AccordionComponent>
            <AccordionComponent
              id={'browseMap'}
              title={t('browseMap')}
              expanded={openBrowseMaps}
              onExpandedChange={handleOpenBrowseMaps}
              sx={{'& .MuiAccordionDetails-root ': {p: 0}, '.MuiPaper-root': {top: 0}}}
            >
              {openBrowseMaps.includes('browseMap') &&
                <BrowseMapsFilter/>
              }
            </AccordionComponent>
            <AccordionComponent
              id={'newlyPublishedFeaturedMaps'}
              title={t('newlyPublishedFeaturedMaps')}
              expanded={openBrowseMaps}
              onExpandedChange={handleOpenBrowseMaps}
              sx={{'& .MuiAccordionDetails-root ': {p: 0}, '.MuiPaper-root': {top: 0}}}
            >
              <ListFeaturedMapCard/>
            </AccordionComponent>
          </>
        }
        {isFilter && <h1>Environmental conflicts in {beautifySlug(slug)}</h1>}
        <AccordionComponent
          id={'recentlyUpdatedCases'}
          title={t('recentlyUpdatedCases')}
          expanded={openBrowseMaps}
          onExpandedChange={handleOpenBrowseMaps}
          sx={{'& .MuiAccordionDetails-root ': {p: 0}, '.MuiPaper-root': {top: 0}}}>
          <ListConflictsCard conflicts={advancedFilters ? resultsAdvancedFilter : results} onFilterPointByIdChange={onFilterPointByIdChange}/>
        </AccordionComponent>


      </InfiniteScroll>
    </Box>
  );

  return <>
    {renderResults()}
  </>;
};
