import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {SelectChangeEvent} from '@mui/material';
import {LANGUAGES} from '../config';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

//STYLES
const inputSelectStyles = {
  padding: '5px 0 5px',
  '&.MuiInput-root:before': {
    borderBottom: '0px solid white'
  },
  '&.MuiInput-root:hover:before': {
    borderBottom: '0px solid white'
  },
  '&.MuiInput-root:hover:after': {
    borderBottom: '0px solid white'
  },
  color: 'white',
  '& .MuiSelect-icon': {
    color: 'white'
  },
  '& .MuiInput-underline:after': {
    border: '2px solid yellow'
  },
};

export const LanguagePicker: FC<{availableLanguages?: Array<string>}> = ({availableLanguages}) => {

  const {i18n, t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSelectLanguage = (e: SelectChangeEvent) => {
    const targetLang = e.target.value;
    navigate(location.pathname + '?translate=' + targetLang);
    i18n.changeLanguage(targetLang);
  };

  return <FormControl variant="standard" sx={{width: 45}}>
    <Select
      value={i18n.resolvedLanguage}
      onChange={handleSelectLanguage}
      sx={inputSelectStyles}
    >
      {availableLanguages?.map(lang =>
        <MenuItem
          dense
          selected={lang === i18n.resolvedLanguage}
          key={lang}
          id={lang}
          value={lang}
          sx={{textTransform: 'capitalize', px: 2}}
        >
          <Box sx={{textTransform: 'capitalize'}}>{t(lang).toUpperCase()}</Box>
        </MenuItem>
      )}
    </Select>
  </FormControl>;
};
