import React, {FC, useState} from 'react';
import Box from '@mui/material/Box';
import {useTranslation} from 'react-i18next';
import Link from '@mui/material/Link';
import {useNavigate} from 'react-router-dom';

export type BrowseByEntityProps = {
  entityName: string,
  data: Array<{ name: string, counter: number, slug: string }>,
}

export const BrowseByEntity: FC<BrowseByEntityProps> = ({data, entityName}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [sortBy, setSortBy] = useState<'alphabetically' | 'frequency'>('frequency');
  const [sortedData, setSortedData] = useState<Array<{ name: string, counter: number, slug: string }>>(data);

  const handleSortBy = (sortBy: 'alphabetically' | 'frequency') => {
    const newSortBy = sortBy === 'alphabetically' ? 'frequency' : 'alphabetically';
    if (newSortBy === 'frequency') { //frecuencia
      const x = sortedData?.sort((a, b) => b.counter - a.counter);
      setSortedData(x);
    } else { //alfabeticamente
      const x = sortedData?.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      setSortedData(x);
    }
    setSortBy(newSortBy);
  };

  const handleFilterClick =(entityName:string, slug: string)=> {
    //?translate=${i18n.resolvedLanguage}
    navigate(`${entityName}/${slug}`);
  };

  return <Box p={2}>
    <Link onClick={() => handleSortBy(sortBy)} sx={{cursor: 'pointer'}}>{sortBy === 'alphabetically' ? t('orderFrequency') : t('orderAlphabetically')}</Link>
    <Box sx={{columns: 3, mt: 2}}>
      {sortedData?.map((entity) => <Box display='flex' key={entity.name + Math.random()}>
        <Box display='flex'>
          <Link onClick={()=> handleFilterClick(entityName, entity.slug)} mr={1} sx={{cursor: 'pointer', textDecoration: 'none', fontSize: 12}}>{entity.name}</Link>
          <b style={{fontSize: 12}}>{entity.counter}</b>
        </Box>
      </Box>)}
    </Box>
  </Box>;
};
