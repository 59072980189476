import React, {FC} from 'react';
import {MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {Value} from './AdvancedSearch';

const selectStyles = {
  width: '100%',
  alignItems: 'flex-start',
  '& .SelectInput-select': {
    bgcolor: 'white',
    fontSize: '12px',
    width: '130px',
  },
  '& .MuiSelect-select': {
    padding: '3.5px 14px',
  },
};

const menuSelectStyles = {
  '& .MuiMenuItem-root': {
    fontSize: '12px',
  }
};

export type SelectComponentProps = {
  filters: any,
  onFilterChange: (_entity: string, _index: number, _option: Value)=> void,
  index: number,
  entity: string,
}

export type Option = {
  id: number | string,
  value: string,
  label: string
}

export const SelectComponent: FC<SelectComponentProps> = ({filters, index, entity, onFilterChange}) => {

  const handleOnFilterChange = (selected: SelectChangeEvent) => {
    const option = filters[index]?.field?.options.find((el: Option) => el.value === selected.target.value) as Value;
    if(option) onFilterChange(entity, index, option);
  };

  return <Select
    sx={selectStyles}
    value={filters[index].value}
    onChange={(selected) => handleOnFilterChange(selected)}
    displayEmpty
    renderValue={(selected) => {
      if (!selected) return <div style={{color: 'slategrey'}}>Select option</div>;
      return <div style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{selected?.label || selected}</div>;
    }}
  >
    <MenuItem disabled value=''>
      <em>
        {filters[index]?.field?.options?.length ? '' : 'Select field first'}
      </em>
    </MenuItem>
    {
      filters[index]?.field?.options?.map((option: Option) => {
        return <MenuItem key={option.id} value={option.value} sx={menuSelectStyles}>{option.label}</MenuItem>;
      })
    }
  </Select>;
};
