import React, {FC, useState} from 'react';

import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import {useTranslation} from 'react-i18next';
import {Navbar} from '../commonTypes';

const ListMenu: FC<{navBarConfig: Navbar}> = ({navBarConfig}) => {
  const {t} = useTranslation();
  const [openedMenuOption, setOpenedMenuOption] = useState<Array<string>>([]);

  const handleClick = (id: string) => {
    setOpenedMenuOption([id]);
  };

  const menuWithSubmenu = (name: string, submenu: Array<{ key: string, url: string }>) => {
    return <>
      <ListItemButton onClick={() => handleClick(name)}>
        <ListItemText primary={t(name).toUpperCase()}/>
      </ListItemButton>
      <Collapse in={openedMenuOption.includes(name)} timeout="auto" unmountOnExit>
        <List dense component="div" disablePadding>
          {
            submenu.map(subm => <ListItemButton key={subm.key} sx={{pl: 4}}>
              <ListItemText primary={`○ ${t(subm.key)}`}/>
            </ListItemButton>)
          }
        </List>
      </Collapse>
    </>;
  };

  return <List sx={{width: '100%', bgcolor: 'common.white', color: 'gray.600'}} component="nav">
    {navBarConfig?.map(el => menuWithSubmenu(el.name, el.submenu))}
  </List>;
};

export default ListMenu;
