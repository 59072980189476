import React, {FC} from 'react';

const FooterLogos: FC = () => {

  return <>
    <a href="http://acknowlej.org/" target="_blank" rel="noreferrer">
      <img src="/images/logos/ack.png" style={{maxHeight: '50px'}} title="Acknowlej.org"
        alt="Acknowlej.org"/>
    </a>
    <a href="http://www.ejolt.org/" target="_blank" rel="noreferrer">
      <img src="/images/logos/ejolt_logo.png" style={{maxHeight: '36px'}} title="Environmental Justice Organisations"
        alt="Environmental Justice Organisations"/>
    </a>
    <a href="http://envjustice.org/" target="_blank" rel="noreferrer">
      <img src="/images/logos/envjustice.png" style={{maxHeight: '40px'}} title="Environmental Justice"
        alt='Environmental Justice'/>
    </a>
    <a href=" https://www.uab.cat/icta/" target="_blank" rel="noreferrer">
      <img src="/images/logos/icta.png" style={{maxHeight: '40px'}} title="ICTA UAB" alt="ICTA UAB"/>
    </a>
    <a href="http://europa.eu/" target="_blank" rel="noreferrer">
      <img src="/images/logos/eu.jpg" title="European Union" style={{maxHeight: '21px'}} alt='European Union'/>
    </a>
    <a href="https://erc.europa.eu/" target="_blank" rel="noreferrer">
      <img src="/images/logos/erc_alpha.png" style={{maxHeight: '40px'}} title="European Research Council"
        alt="European Research Council"/>
    </a>
    <a href="https://www.maptiler.com/copyright/" target="_blank" rel="noreferrer">
      <img src="/images/logos/maptiler.png" title="MapTiler" style={{maxHeight: '27px'}} alt='MapTiler'/>
    </a>
    <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noreferrer">
      <img src="/images/logos/OSM.png" title="OpenStreetMap" style={{maxHeight: '35px'}} alt='OpenStreetMap'/>
    </a>
    <a href="https://creativecommons.org/licenses/by-nc-sa/3.0/" target="_blank" rel="noreferrer">
      <img src="/images/logos/cc.png" title="CC Attribution NonCommercial ShareAlike" style={{maxHeight: '21px'}}
        alt="CC Attribution NonCommercial ShareAlike"/>
    </a>
  </>;
};

export default FooterLogos;
