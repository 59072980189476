import React, {FC} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {Conflict, FeaturedMap} from '../../../commonTypes';
import {Carousel} from './carousel/Carousel';
import {FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton} from 'react-share';
import {useNavigate, useParams} from 'react-router-dom';
import {ENTITIES, MODES, URL_RRSS} from '../../../config';
import PrintIcon from '@mui/icons-material/Print';

type Presentation = Conflict['presentation'];

export type ConflictPresentationProps = {
  presentation: Presentation | FeaturedMap,
  mode?: string | null
};

export const ConflictPresentation: FC<ConflictPresentationProps> = ({presentation, mode = MODES.INFO,  children}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const {
    name,
    images,
  } = presentation;

  const {filter, slug} = useParams();
  const url = `${URL_RRSS}/${filter}/${slug}`;
  const title = `Checkout ${presentation.name} on #EJATLAS:`;

  const handleOnConflictPrint = () => {
    navigate(`../print/${slug}`);
  };

  return <Box m={2}>
    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      {name &&
        <Typography variant="h5" gutterBottom sx={{width: '90%', fontWeight: 'bold'}}>
          {name}
        </Typography>
      }

      <Box sx={{color: '#666', ml: 2}}>
        {mode === MODES.INFO &&
          <Box sx={{display: 'flex', justifyContent: 'space-evenly'}}>
            <Box mr={1}>
              <FacebookShareButton url={url} quote={title}>
                <FacebookIcon size={22}/>
              </FacebookShareButton>
            </Box>
            <Box mr={1}>
              <TwitterShareButton url={url} title={title}>
                <TwitterIcon size={22}/>
              </TwitterShareButton>
            </Box>
            {filter !== ENTITIES.FEATURED &&
              <Box sx={{cursor: 'pointer'}}>
                <PrintIcon onClick={handleOnConflictPrint} color="primary"/>
              </Box>
            }
          </Box>
        }
        {(presentation as Presentation)?.updated_at &&
          <>
            <Typography variant="caption" display="block" sx={{whiteSpace: 'nowrap', textAlign: 'center'}}>
              {t('lastUpdate')}:
            </Typography>
            <Typography variant="caption" display="block" sx={{fontWeight: 'bold', textAlign: 'center'}}>
              {(presentation as Presentation).updated_at}
            </Typography>
          </>
        }
      </Box>
    </Box>
    {children && <Box mb={1}>{children}</Box>}

    {mode === MODES.INFO &&
      <Carousel carouselImages={images}/>
    }
    {presentation?.headline &&
      <Typography variant="body2" component="div" gutterBottom sx={{m: 2, fontStyle: 'italic'}}>
        {presentation.headline}
      </Typography>
    }
  </Box>;
};