import React, {FC, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {useTranslation} from 'react-i18next';
import {ConflictPresentation} from '../conflict-detail/components/ConflictPresentation';
import {AccordionComponent} from '../conflict-detail/components/AccordionComponent';
import {ConflictDescription} from '../conflict-detail/components/ConflictDescription';
import Link from '@mui/material/Link';
import {useNavigate, useParams} from 'react-router-dom';
import useFetch from '@geomatico/geocomponents/hooks/useFetch';
import {API_GET_FEATURED_MAP_DETAIL, ENTITIES} from '../../config';
import Button from '@mui/material/Button';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import {FeaturedMapLayerList} from './FeaturedMapLayerList';
import {FeaturedMap} from '../../commonTypes';

export interface FeaturedMapDetailProps {
  onFilterByIds: (_ids: Array<number>) => void,
  onWmsLayersChange: (_wmsLayers: Array<Layer>) => void,
  wmsLayers: Array<Layer>,
}

export type Layer = {
  legend: string,
  name: string,
  layer_name: string,
  description: string,
  type: string,
  url: string,
  visible?: boolean,
};

export const FeaturedMapDetail: FC<FeaturedMapDetailProps> = ({onFilterByIds, wmsLayers, onWmsLayersChange}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [expanded, setExpanded] = useState(['cases', 'description']);

  const {slug} = useParams();

  const {data} = useFetch(slug && API_GET_FEATURED_MAP_DETAIL(slug));
  const featuredDetail: FeaturedMap | undefined = data ? {...data} : undefined;

  useEffect(() => {
    if (featuredDetail && !wmsLayers.length) {
      onFilterByIds(featuredDetail.ids);
      const layersCopy = [...featuredDetail.layer_list.map((layer, i) => ({...layer, visible: i === 0 }))];
      if(layersCopy.length) onWmsLayersChange(layersCopy);
    }
  }, [featuredDetail]);

  const handleAccordionExpanded = (panel: string) => {
    let newExpandedPanels = [];
    if (expanded.includes(panel)) {
      newExpandedPanels = [...expanded];
      newExpandedPanels.splice(expanded.findIndex(el => el === panel), 1);
    } else {
      newExpandedPanels = [...expanded, panel];
    }
    setExpanded(newExpandedPanels);
  };

  const handleOnGoBack = () => {
    onWmsLayersChange([]);
    navigate('/', {replace: true});
  };

  return <Box id='FeaturedMap-detail'>
    <Button onClick={handleOnGoBack} size="small" variant="text" sx={{mt: 1, ml: 1}} startIcon={<KeyboardReturnIcon/>}>{t('goBack')}</Button>
    {featuredDetail?.name && <ConflictPresentation presentation={featuredDetail}/>}

    <AccordionComponent id={'description'} title={t('description')} onExpandedChange={handleAccordionExpanded} expanded={expanded}>
      {featuredDetail &&
        <ConflictDescription description={featuredDetail.description} numberOfColumns={1}/>
      }
    </AccordionComponent>

    <AccordionComponent id={'legend'} title={t('legend')} onExpandedChange={handleAccordionExpanded} expanded={expanded}>
      {featuredDetail &&
        <FeaturedMapLayerList wmsLayers={wmsLayers} onWmsLayersChange={onWmsLayersChange}/>
      }
    </AccordionComponent>
    <AccordionComponent id={'cases'} title={t('cases')} onExpandedChange={handleAccordionExpanded} expanded={expanded}>
      <Box display='flex' flexDirection='column'>
        {featuredDetail?.conflicts.map((c: { name: string, slug: string }) =>
          <Link target="_blank" rel="noopener" href={`/${ENTITIES.CONFLICT}/${c.slug}`} key={c.slug} underline="hover" color="inherit" fontWeight='bold' mt={1}>{c.name}</Link>
        )}
      </Box>

    </AccordionComponent>
  </Box>;
};
