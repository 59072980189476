import React, {FC, useState} from 'react';
import {Carousel as ImageCarousel} from 'react-responsive-carousel';
import './carousel.css';
import Box from '@mui/material/Box';
import {CarouselImage} from '../../../../commonTypes';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {Fab} from '@mui/material';
import {SxProps} from '@mui/system';
export type CarouselProps = {
  carouselImages: Array<CarouselImage>
};

export const Carousel: FC<CarouselProps> = ({carouselImages}) => {

  const [hoveredLegend, setHoveredLegend] = useState<string | undefined>();

  const openInNewTab = (index: number) => {
    const newWindow = window.open(carouselImages[index].image, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleHoveredLegend = (title: string | undefined) => {
    setHoveredLegend(title);
  };
  const arrowStyles: SxProps = {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 40,
    height: 40,
    cursor: 'pointer',
    borderRadius: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };


  return <Box>
    <ImageCarousel
      dynamicHeight={false}
      centerMode={true}
      centerSlidePercentage={60} // meter media query
      showThumbs={false}
      showStatus={false}
      showIndicators={false}
      emulateTouch={true}
      stopOnHover={true}
      infiniteLoop={false}
      onClickItem={openInNewTab}
      autoPlay={false}
      renderArrowPrev={(onClickHandler) =>
        <Fab onClick={onClickHandler}  sx={{...arrowStyles, left: '10px'}} color="primary">
          <ArrowLeftIcon sx={{fontSize: '32px'}}/>
        </Fab>
      }
      renderArrowNext={(onClickHandler) =>
        <Fab onClick={onClickHandler} sx={{...arrowStyles, right: '10px'}} color="primary">
          <ArrowRightIcon sx={{fontSize: '32px'}}/>
        </Fab>
      }
    >
      {
        carouselImages?.map((image, i) => {
          return <div
            onMouseEnter={() => handleHoveredLegend(image.name)}
            onMouseLeave={() => handleHoveredLegend(undefined)}
            key={i}
            style={{height: '100%', cursor: 'pointer', border: '3px solid white'}}
          >
            <img alt={image.name} src={image.image}/>
            <p id={image.name} style={{opacity: hoveredLegend === image.name ? 0.7 : 0, fontSize: '10px', padding: '4px'}} className="legend">
              <b>{image.name}</b> - {image.description}
            </p>
          </div>;
        })
      }
    </ImageCarousel>
  </Box>;
};
