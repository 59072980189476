import React, {FC, ReactNode} from 'react';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import MuiAccordionSummary, {AccordionSummaryProps} from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {SxProps} from '@mui/system';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}}/>}
    {...props}
  />
))(({theme}) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  minHeight: '33px',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    marginLeft: theme.spacing(1),
  },
}));

export type AccordionComponentProps = {
  id: string,
  title: string,
  titleVariant?: 'body1' | 'body2' | 'button' | 'caption' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'inherit' | 'overline' | 'subtitle1' | 'subtitle2',
  children: ReactNode,
  expanded: Array<string>,
  onExpandedChange: (_newExpandedPanel: string)=> void,
  sx?: SxProps,
}

export const AccordionComponent: FC<AccordionComponentProps> = ({id, title, titleVariant, children, expanded, onExpandedChange, sx}) => {

  return <Accordion expanded={expanded.includes(id)} sx={sx} onChange={()=> onExpandedChange(id)}>
    <AccordionSummary>
      <Typography variant={titleVariant || 'body1'} sx={{fontWeight: 'bold'}}>{title}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      {children}
    </AccordionDetails>
  </Accordion>;
};
