import React, {FC} from 'react';
//MUI
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import styled from '@mui/styles/styled';

//COMPONENTS
import GeomaticoLink from '../../components/GeomaticoLink';

//IMPORTS
import ListMenu from '../../components/ListMenu';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Manifest} from '../../commonTypes';

const ScrollableContent = styled(Box)({
  overflow: 'auto',
});

const stackSx = {
  height: '100%',
  overflow: 'hidden',
};

export type SidePanelContentProps = {
  navBarConfig: Manifest['navBarConfig'],
};

const LeftSidePanelContent: FC<SidePanelContentProps> = ({navBarConfig}) => {
  const mobileMode = useMediaQuery('(max-width:900px)');

  return <Stack id='leftSidePanelContent' sx={stackSx}>
    <ScrollableContent>
      {mobileMode && <ListMenu navBarConfig={navBarConfig}/>}
    </ScrollableContent>
    <GeomaticoLink/>
  </Stack>;
};

export default LeftSidePanelContent;
