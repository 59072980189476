import React, {FC, useState} from 'react';
import {ConflictPresentation} from './components/ConflictPresentation';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import {Conflict} from '../../commonTypes';
import {useTranslation} from 'react-i18next';
import {AccordionComponent} from './components/AccordionComponent';
import {ConflictBasicData} from './components/ConflictBasicData';
import ConflictLanguage from './components/ConflictLanguage';
import {ConflictDescription} from './components/ConflictDescription';
import {ConflictSource} from './components/ConflictSource';
import {ConflictDetailsAndActors} from './components/ConflictDetailsAndActors';
import {ConflictMobilization} from './components/ConflictMobilization';
import {ConflictImpacts} from './components/ConflictImpacts';
import {ConflictMetainformation} from './components/ConflictMetainformation';
import {ConflictOutcome} from './components/ConflictOutcome';
import {ConflictSourcesAndMaterials} from './components/ConflictSourcesAndMaterials';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import {useNavigate} from 'react-router-dom';
import {MODES} from '../../config';
import { ConflictRelations } from './components/ConflictRelations';

export const generateSection = (title: string, section: string | number | null | undefined, divider = true) => {
  return <>
    <Typography variant="h6" gutterBottom>
      {title}
    </Typography>
    <Typography variant="body2" component="div" gutterBottom sx={{mb: 2}}>
      {section}
    </Typography>
    {divider && <Divider sx={{mb: 2}}/>}
  </>;
};

export interface ConflictDetailProps {
  conflict: Conflict | null,
  mode?: string,
  onLinkClick: (_filter: string, _value: string) => void,
  onConflictLangChange: (_lang: string) => void,
}

export const ConflictDetail: FC<ConflictDetailProps> = ({conflict, onConflictLangChange, onLinkClick, mode = MODES.INFO, children}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [expanded, setExpanded] = useState(['description', 'basicData', 'conflictSource', 'detailsAndActors', 'conflictMobilization', 'impacts', 'outcome', 'metainformation', 'sourcesAndMaterials']);

  const handleAccordionExpanded = (panel: string) => {
    let newExpandedPanels = [];
    if (expanded.includes(panel)) {
      newExpandedPanels = [...expanded];
      newExpandedPanels.splice(expanded.findIndex(el => el === panel), 1);
    } else {
      newExpandedPanels = [...expanded, panel];
    }
    setExpanded(newExpandedPanels);
  };

  const handleOnGoBack = () => {
    navigate('/', {replace: true});
  };

  return <Box id='conflict-detail'>
    {conflict &&
      <>
        <Button onClick={handleOnGoBack} size="small" variant="text" sx={{mt: 1, ml: 1}} startIcon={<KeyboardReturnIcon/>}>{t('goBack')}</Button>
        <ConflictPresentation mode={mode} presentation={conflict.presentation}>
          {mode === MODES.INFO &&
            <ConflictLanguage
              availableLangs={conflict.presentation.languages}
              activeLang={conflict.presentation.locale}
              onLanguageChange={onConflictLangChange}
              style='primary'
              motherTongue={conflict.presentation.mother_tongue}
            />
          }
        </ConflictPresentation>
        {mode === MODES.PRINT &&
          <div>{children}</div>
        }
        <AccordionComponent id={'description'} title={t('conflictDescription')} onExpandedChange={handleAccordionExpanded} expanded={expanded}>
          <ConflictDescription description={conflict.basic_data.description} mode={mode} numberOfColumns={0}/>
        </AccordionComponent>

        <AccordionComponent id={'basicData'} title={t('basicData')} onExpandedChange={handleAccordionExpanded} expanded={expanded}>
          <ConflictBasicData name={conflict.presentation.name} basicData={conflict.basic_data} onLinkClick={onLinkClick}/>
        </AccordionComponent>

        <AccordionComponent id={'conflictSource'} title={t('conflictSource')} onExpandedChange={handleAccordionExpanded} expanded={expanded}>
          <ConflictSource source={conflict.source} onLinkClick={onLinkClick}/>
        </AccordionComponent>

        <AccordionComponent id={'detailsAndActors'} title={t('conflictDetailsAndActors')} onExpandedChange={handleAccordionExpanded} expanded={expanded}>
          <ConflictDetailsAndActors details_and_actors={conflict.details_and_actors} onLinkClick={onLinkClick}/>
        </AccordionComponent>

        <AccordionComponent id={'conflictMobilization'} title={t('conflictMobilization')} onExpandedChange={handleAccordionExpanded} expanded={expanded}>
          <ConflictMobilization mobilization={conflict.mobilization}/>
        </AccordionComponent>

        <AccordionComponent id={'impacts'} title={t('conflictImpacts')} onExpandedChange={handleAccordionExpanded} expanded={expanded}>
          <ConflictImpacts impacts={conflict.impacts}/>
        </AccordionComponent>

        <AccordionComponent id={'outcome'} title={t('conflictOutcome')} onExpandedChange={handleAccordionExpanded} expanded={expanded}>
          <ConflictOutcome outcome={conflict.outcome}/>
        </AccordionComponent>

        <AccordionComponent id={'sourcesAndMaterials'} title={t('conflictSourceAndMaterials')} onExpandedChange={handleAccordionExpanded} expanded={expanded}>
          <ConflictSourcesAndMaterials sources_and_materials={conflict.sources_and_materials}/>
        </AccordionComponent>

        <AccordionComponent id={'metainformation'} title={t('conflictMetainformation')} onExpandedChange={handleAccordionExpanded} expanded={expanded}>
          <ConflictMetainformation metainformation={conflict.metainformation}/>
        </AccordionComponent>

        <AccordionComponent id={'relations'} title={t('conflictRelations')} onExpandedChange={handleAccordionExpanded} expanded={expanded} sx={{'& .MuiCollapse-root': {marginBottom: '100px'}}}>
          <ConflictRelations relations={conflict.relations} onLinkClick={onLinkClick}/>
        </AccordionComponent>
      </>
    }
  </Box>;
};
