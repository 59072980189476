import {LegendGroup, LegendValue} from './commonTypes';
import {FilterFront, FilterProcessed, Value} from './components/advanced-search/AdvancedSearch';

export const getIdByGroupValueName = (legend: LegendGroup[], slug: string | undefined) => {
  if (!legend?.length || !slug) return;
  const group: LegendValue[] = legend.map((le: LegendGroup) => le.values).flat();
  return group.find((el) => el.slug === slug)?.id;
};

export const processFilterToFrontForm = (filterback: any, filterManifest: any) => {
  const x: Array<FilterFront> = [];
  if (filterManifest && filterback?.length) {
    for (let i = 0; i < filterback.length; i++) {
      if (i % 2 === 0) {
        const value = filterManifest[filterback[i].field].options
          ? filterManifest[filterback[i].field].options.find((el: any) => el.id === filterback[i].value)
          : {id: filterback[i].value};
        const r: FilterFront = {
          field: filterManifest[filterback[i].field],
          value: value,
          operation: filterback[i].operation,
        };
        if (i > 0) r.operator = filterback[i - 1];
        x.push(r);
      }
    }
  }
  return x;
};

export const processFrontFilterToBackForm = (resultsWithoutProcess: Array<FilterFront>) => {
  const updatedFilters = resultsWithoutProcess.map((fil, index) => {
    return {...fil, field: fil.field.name, value: fil.value, operator: index === 0 ? null : fil.operator};
  });
  const processed: Array<FilterProcessed> = [];
  updatedFilters.forEach((el) => {
    if (el.operator) processed.push(el.operator);
    const copy = Object.assign({}, el);
    const value = copy.value as Value;
    const p: FilterProcessed = {field: copy.field as string, value: value.id, operation: copy.operation};
    processed.push(p);
  });
  return processed;
};