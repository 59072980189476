import React, {FC} from 'react';
import Box from '@mui/material/Box';
import {useTranslation} from 'react-i18next';
import Typography from '@mui/material/Typography';
import {Conflict} from '../../../commonTypes';
import { Link } from '@mui/material';

export type ConflictRelationsProps = {
  relations: Conflict['relations'],
  onLinkClick: (_filter: string, _value: string) => void,
}

export const ConflictRelations: FC<ConflictRelationsProps> = ({relations, onLinkClick}) => {
  const {t} = useTranslation();
  const {relations: the_relations} = relations;

  return <Box>
    {
      the_relations.map((relation) => {
        return <Link key={relation.slug} color='secondary' onClick={() => onLinkClick('conflict', relation.slug)} sx={{cursor: 'pointer'}}>
          <Typography variant="body2" component="div" gutterBottom>
            {relation.name}<br/>
          </Typography>
        </Link>;
      })
    }
  </Box>;
};
