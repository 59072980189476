import React, {FC, useCallback, useMemo} from 'react';
import Map from '@geomatico/geocomponents/Map';
import usePosition, {LatLonZoomType} from '../hooks/usePosition';
import {Sources, AnyLayer} from 'mapbox-gl';
import GeoJSON from 'geojson';
import {Marker} from './Marker';
import {MAPSTYLES} from '../config';

export interface MiniMapProps {
  feature: GeoJSON.Feature<GeoJSON.Point>
}

const MiniMap: FC<MiniMapProps> = ({feature}) => {
  const {viewport, setViewport} = usePosition();

  const mapRef = useCallback(map => {
    if (map && feature) {
      const latLonZoom: LatLonZoomType = {zoom: 14, latitude: feature.geometry.coordinates[1], longitude: feature.geometry.coordinates[0]};
      setViewport(latLonZoom);
    }
  }, [feature]);

  const sources = useMemo<Sources | undefined>(() => feature ? {data: {type: 'geojson', data: feature}} : undefined, [feature]);

  const layers = useMemo<Array<AnyLayer>>(() => feature
    ? [{
      'id': 'points',
      'source': 'data',
      'type': 'circle',
      'maxzoom': 20,
      'paint': {
        'circle-color': 'red',
        'circle-stroke-width': 1,
        'circle-radius': 1,
        'circle-stroke-color': '#FFFFFF',
      }}] : [], [feature]);

  return <Map
    ref={mapRef}
    mapStyle={MAPSTYLES[1].id}
    viewport={viewport}
    onViewportChange={setViewport}
    sources={sources}
    layers={layers}
    maxZoom={18}
    attributionControl={false}
  >
    <Marker feature={feature}/>

  </Map>;
};

export default MiniMap;
