import React, {FC, useEffect, useState} from 'react';
import {API_CONFLICT_DETAIL, API_MANIFEST, API_MAP_DATA, MODES} from '../config';
import useFetch from '@geomatico/geocomponents/hooks/useFetch';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {ConflictDetail} from './conflict-detail/ConflictDetail';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {Container, Fab} from '@mui/material';
import MiniMap from './MiniMap';
import PrintIcon from '@mui/icons-material/Print';
import {CarouselImage, LegendGroup, LegendValue} from '../commonTypes';
import GeoJSON, {Feature} from 'geojson';

export const PrintConflict: FC = () => {
  const {slug} = useParams();
  const {i18n} = useTranslation();
  const [feature, setFeature] = useState();
  const {data: conflict} = useFetch(slug && API_CONFLICT_DETAIL(slug, i18n.resolvedLanguage));
  const {data} = useFetch(API_MAP_DATA);
  const {data: manifest} = useFetch(API_MANIFEST);

  const getLegendSlugAndColorFromManifest =(feature: GeoJSON.Feature<GeoJSON.Point>)=> {
    const x = manifest.legend.values.find((group: LegendGroup) => group.name === 'category');
    const category = x.values.find((value: LegendValue) => value.id === feature?.properties?.category);
    return {color: category.color, legend_slug: category.slug};
  };

  useEffect(() => {
    if (data && !feature) {
      const feature = data?.features.find((feat: Feature) => feat.properties?.slug === slug);
      const {legend_slug, color} = getLegendSlugAndColorFromManifest(data.features[0]);
      feature.properties.legend_slug = legend_slug;
      feature.properties.color = color;
      setFeature(feature);
    }
  }, [data]);

  const handlePrintConflict = () => {
    window.print();
  };

  return <Container fixed>
    <Box sx={{display: 'flex', justifyContent: 'end', mt: 2}}>
      <Fab size={'small'} onClick={handlePrintConflict} color="primary" aria-label="edit">
        <PrintIcon/>
      </Fab>
    </Box>

    {conflict && data &&
      <ConflictDetail conflict={conflict} mode={MODES.PRINT} onLinkClick={() => null} onConflictLangChange={() => null}>
        {feature &&
          <Box sx={{height: '300px', mb: 2}}>
            <MiniMap feature={feature}/>
          </Box>
        }
      </ConflictDetail>
    }
    {
      conflict?.presentation.images.map((image: CarouselImage) => {
        return <Box key={image.image}>
          <img src={image.image}/>;
          {image.description && <Typography variant="caption" display="block" gutterBottom>
            {image.name}-{image.description}
          </Typography>
          }
        </Box>;
      })
    }
    <Divider sx={{mb: 3}}/>
  </Container>;
};
