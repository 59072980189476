import React, {FC} from 'react';
import {CircularProgress} from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useTranslation} from 'react-i18next';

export type CounterProps = {
  isVisible: boolean
};

export const Loader: FC<CounterProps> = ({isVisible}) => {
  const {t} = useTranslation();

  return <>
    {isVisible &&
      <Box display='flex' alignItems='center' flexDirection='column'>
        <CircularProgress size={20}/>
        <Typography variant='caption' gutterBottom sx={{fontStyle: 'italic', mt: 1}}>{t('loading')}...</Typography>
      </Box>
    }</>;
};
