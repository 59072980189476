import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {Navigate} from 'react-router-dom';
import MapView from '../views/Map';
import {PrintConflict} from '../components/PrintConflict';
import IndexFeaturedMapView from '../views/featuredMap';

const AppRoutes = () =>
  <BrowserRouter>
    <Routes>
      <Route exact path=":filter/:slug" element={<MapView/>}/>
      <Route exact path="editfilter/:filterUUID" element={<MapView/>}/>
      <Route exact path="print/:slug" element={<PrintConflict/>}/>
      <Route exact path="featured" element={<IndexFeaturedMapView/>}/>
      <Route exact path="" element={<MapView/>}/>
      <Route path="*" element={<Navigate to={''}/>}/>
    </Routes>
  </BrowserRouter>;

export default AppRoutes;

