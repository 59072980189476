import React, {FC, useMemo, useRef, useState} from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useTranslation} from 'react-i18next';
import {useContainerDimensions} from '../../../hooks/useContainerDimensions';
import {MODES, RIGHT_DEFAULT_DRAWER_WIDTH} from '../../../config';
import {sanitizeHTML} from '../../../sanitazeHTML';

export type ConflictDescriptionProps = {
  description: string,
  mode?: string,
  numberOfColumns?: number,
};

const MAXNUMLETTERS = 400;

export const ConflictDescription: FC<ConflictDescriptionProps> = ({description, mode = MODES.INFO, numberOfColumns = 0}) => {
  const componentRef = useRef<HTMLParagraphElement | null>(null);
  const {width: containerWidth} = useContainerDimensions(componentRef);

  const {t} = useTranslation();
  const [seeMore, setSeeMore] = useState(false);

  const sanitizedDesc = useMemo(() => sanitizeHTML({html: description}), [description]);
  const sanitizedDescCutted = useMemo(() => sanitizeHTML({html: description}, MAXNUMLETTERS), [description]);

  const getNumberOfColumns = () => {
    return containerWidth > RIGHT_DEFAULT_DRAWER_WIDTH ? 3 : (containerWidth < 300 ? 1 : 2);
  };

  return <Box ref={componentRef}>
    {mode === MODES.INFO ?
      <Typography variant="body2" gutterBottom sx={{columnCount: numberOfColumns? numberOfColumns : getNumberOfColumns()}}>
        {seeMore ? sanitizedDesc : sanitizedDescCutted}
      </Typography>
      : <Typography variant="body2" gutterBottom>
        {sanitizedDesc}
      </Typography>
    }

    {mode === MODES.INFO && description.length > MAXNUMLETTERS &&
      <Grid container justifyContent="flex-end" mt={2}>
        <Button variant="contained" onClick={() => setSeeMore(!seeMore)}>{seeMore ? t('seeLess') : t('seeMore')}</Button>
      </Grid>
    }
  </Box>;
};

