import React, {FC} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {useTranslation} from 'react-i18next';
import Link from '@mui/material/Link';
import {generateSection} from '../ConflictDetail';
import {Conflict} from '../../../commonTypes';
import {sanitizeHTML} from '../../../sanitazeHTML';

export type ConflictDetailsAndActorsProps = {
  details_and_actors: Conflict['details_and_actors'],
  onLinkClick: (_filter: string, _value: string) => void,
}

export const ConflictDetailsAndActors: FC<ConflictDetailsAndActorsProps> = ({details_and_actors, onLinkClick}) => {
  const {
    project_details,
    project_area,
    investment,
    population_type,
    start_date,
    end_date,
    companies,
    govt_actors,
    affected_people,
    ejos,
    supporters,
  } = details_and_actors;

  const {t} = useTranslation();

  return <Box>
    <Typography variant="h6" gutterBottom>
      {t('conflictDetails')}
    </Typography>
    <Typography variant="body2" component="div" gutterBottom sx={{mb: 2}}>
      {sanitizeHTML({html: project_details ? project_details : undefined})}
    </Typography>
    <Divider sx={{mb: 2}}/>
    {generateSection(t('conflictProjectArea'), project_area)}
    {generateSection(t('conflictLevelOfInvestment'), investment)}
    {generateSection(t('conflictTypeOfPopulation'), population_type)}

    {generateSection(t('conflictAffectedPopulation'), affected_people)}

    {generateSection(t('conflictStart'), start_date)}
    {generateSection(t('conflictEnd'), end_date)}

    <Typography variant="h6" gutterBottom>
      {t('conflictCompany')}
    </Typography>
    {companies?.map(({name, slug, country, involvement}) => {
      return <Typography key={slug + involvement} component="div" variant="body2" gutterBottom sx={{display: 'flex'}}>
        <Link color='secondary' onClick={() => onLinkClick('company', slug)} sx={{cursor: 'pointer', mr: 0.5}}>
          <Typography variant="body2" component="div" gutterBottom>
            {name}
          </Typography>
        </Link>
        { country &&
          <>
            <Typography variant="body2" component="div" gutterBottom>from</Typography>
            <Link color='secondary' onClick={() => onLinkClick('country', country.slug)} sx={{cursor: 'pointer', ml: 0.5}}>
              <Typography variant="body2" component="div" gutterBottom>
                {country.name}
              </Typography>
            </Link>
          </>
        }
        {involvement && <span> - {involvement}</span>}
      </Typography>;
    })
    }
    <Divider sx={{mb: 3}}/>
    {generateSection(t('conflictActors'), govt_actors)}
    <Typography variant="h6" gutterBottom>
      {t('conflictSupporters')}
    </Typography>
    {supporters?.map(({name, slug, country, involvement}) => {
      return <Typography key={slug} component="div" variant="body2" gutterBottom sx={{display: 'flex'}}>
        <Link color='secondary' onClick={() => onLinkClick('supporter', slug)} sx={{cursor: 'pointer', mr: 0.5}}>
          <Typography variant="body2" component="div" gutterBottom>
            {name}
          </Typography>
        </Link>
        {
          country && 
          <>
            <Typography variant="body2" component="div" gutterBottom>from</Typography>
            <Link color='secondary' onClick={() => onLinkClick('country', country.slug)} sx={{cursor: 'pointer', ml: 0.5}}>
              <Typography variant="body2" component="div" gutterBottom>
                {country.name}
              </Typography>
            </Link>
          </>
        }
        {involvement && <Typography variant="body2" component="div" gutterBottom sx={{ml: 0.5}}>{involvement}</Typography>}
      </Typography>;
    })
    }
    <Divider sx={{mb: 3}}/>
    {generateSection(t('conflictEjos'), ejos, false)}
  </Box>;
};
