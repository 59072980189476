import React, {FC} from 'react';
import Box from '@mui/material/Box';
import {useTranslation} from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useNavigate} from 'react-router-dom';
import {SxProps} from '@mui/system';

const floatingLogoStyles: SxProps = {
  zIndex: 2,
  background: '#024357',
  color: 'white',
  padding: '3px 22px',
  fontWeight: 'bold',
  position: 'absolute',
  top: '14px',
  whiteSpace: 'nowrap',
  left: '58px',
  fontFamily: 'Ubuntu, "Helvetica Neue", Helvetica, Arial, sans-serif',
  fontSize: '16px',
  cursor: 'pointer'
};

type FloatingLogoProps = {
  isHeaderOpen: boolean,
}

export const FloatingLogo: FC<FloatingLogoProps> = ({isHeaderOpen}) => {
  const {t} = useTranslation();
  const desktopMode = useMediaQuery('(min-width:800px)');
  const navigate = useNavigate();
  const handleNavigation = () => navigate('/');

  return <Box onClick={handleNavigation} sx={{position: 'absolute', top: isHeaderOpen ? 90 : 55, left: 8}}>
    {desktopMode &&
      <>
        <Box sx={floatingLogoStyles}>{t('atlas')}</Box>
        <a style={{zIndex: 20, position: 'absolute'}}>
          <img src="/images/ejatlas-logo.png" style={{maxHeight: '150px'}} title={t('atlas')}/>
        </a></>
    }
  </Box>;
};
