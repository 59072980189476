import React, {FC} from 'react';
import {LegendIcon} from './LegendSelector';
import GeoJSON from 'geojson';
import {Marker as MapMarker} from 'react-map-gl';

export type MarkerProps = {
  feature: GeoJSON.Feature<GeoJSON.Point> | undefined
};

export const Marker: FC<MarkerProps> = ({feature}) => {
  return <>
    {feature &&
      <MapMarker longitude={feature.properties?.lng || feature.geometry.coordinates[0]} latitude={feature.properties?.lat || feature.geometry.coordinates[1]} offset={[2, 15]} anchor="bottom">
        <LegendIcon size={50} color={feature.properties?.color} slug={feature.properties?.legend_slug}/>
      </MapMarker>
    }
  </>;
};
