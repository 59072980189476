import React, {FC, useEffect, useState} from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import Box from '@mui/material/Box';
import {FilterResponse} from './advanced-search/AdvancedSearch';

export type SaveFilterDialogProps = {
  isOpen: boolean,
  isEdit: boolean,
  filter: FilterResponse | null,
  maxResults: number | null,
  onOpenSaveFilterDialog: (_state: boolean) => void,
  onSaveFilter: (_result: CustomFilterForm) => void,
};

export type CustomFilterForm = {
  name: string,
  description: string,
  isPublic: boolean,
  maxResults: number | null,
}

export const SaveFilterDialog: FC<SaveFilterDialogProps> = ({isOpen, isEdit, filter, maxResults, onOpenSaveFilterDialog, onSaveFilter}) => {
  const {t} = useTranslation();
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isPublic, setIsPublic] = useState<boolean>(true);

  useEffect(()=> {
    if(filter) {
      setDescription(filter.description);
      setName(filter.name);
      setIsPublic(filter.public);
    }
  }, [filter]);

  const handleClose = () => {
    onOpenSaveFilterDialog(false);
  };

  const saveFilter = () => {
    onSaveFilter({name, description, isPublic, maxResults});
    onOpenSaveFilterDialog(false);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPublic(event.target.checked);
  };

  return <>
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{isEdit ? t('edit') : t('save')} {t('filter')} </DialogTitle>
      <DialogContent>
        <TextField
          value={name}
          autoFocus
          margin="dense"
          id="name"
          label={t('name')}
          type="text"
          fullWidth
          variant="standard"
          sx={{mt: 2}}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setName(event.target.value);
          }}
        />
        <TextField
          value={description}
          margin="dense"
          id="name"
          label={t('description')}
          type="text"
          fullWidth
          rows={4}
          variant="standard"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setDescription(event.target.value);
          }}
        />
        <Box mt={2}>
          {t('isPublic')} <Checkbox checked={isPublic} onChange={handleCheckboxChange}/>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('cancel')}</Button>
        <Button onClick={saveFilter}>{isEdit ? t('edit') : t('save')}</Button>
      </DialogActions>
    </Dialog>
  </>;
};
