import {useEffect, useState} from 'react';
import useFetch from '@geomatico/geocomponents/hooks/useFetch';
import {User} from '../commonTypes';
import {API_USER_SESSION} from '../config';
import {useCookies} from 'react-cookie';

export const useUserSession = () => {
  const [cookies] = useCookies(['csrftoken']);
  const token = cookies.csrftoken;

  const [user, setUser] = useState<User | null>(null);

  const {data: session} = useFetch(token && API_USER_SESSION, {headers: {'X-CSRFToken': token}});

  useEffect(() => {
    setUser(session);
  }, [session]);

  return {user: user, token: token};
};