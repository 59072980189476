import React, {FC, useEffect, useMemo, useState} from 'react';
import Box from '@mui/material/Box';
import {Autocomplete, Button, Fab, FormControl, IconButton, MenuItem, Select, TextField} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import {API_GET_FILTER_BY_ID, BASE_URL_API, operators} from '../../config';
import useFetch from '@geomatico/geocomponents/hooks/useFetch';
import {SelectComponent} from './SelectComponent';
import {AutocompleteWithSearch} from './AutocompleteWithSearch';
import {useTranslation} from 'react-i18next';
import {CustomFilterForm, SaveFilterDialog} from '../SaveFilterDialog';
import {useNavigate, useParams} from 'react-router-dom';
import {Loader} from '../Loader';
import {processFilterToFrontForm, processFrontFilterToBackForm} from '../../auxiliar';
import {useUserSession} from '../../hooks/useUserSession';
import {SxProps} from '@mui/system';

const METHODS = {POST: 'POST', PUT: 'PUT'};

const loaderBoxStyles: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: '20%',
  width: '100%',
  height: '80%',
  position: 'absolute',
  bgcolor: 'white',
  opacity: 0.80
};

const selectStyles = {
  width: '100%',
  alignItems: 'flex-start',
  '& .SelectInput-select': {
    bgcolor: 'white',
    fontSize: '12px',
    width: '130px',
  },
  '& .MuiSelect-select': {
    padding: '3.5px 14px',
  },
};

const menuSelectStyles = {
  '& .MuiMenuItem-root': {
    fontSize: '12px',
  }
};

const autocompleteStyles = {
  '& .MuiOutlinedInput-root': {
    padding: '1px 9px',
    width: '120px',
  },
  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: '2.5px 4px 2.5px 6px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
};

export type Value = {
  id: number | string,
  value: number | string,
  label: string
}
type Operator = 'and' | 'or'
export type Field = {
  name: string,
  type?: string,
  label: string,
  options?: Array<Value>
  url?: string,
  value?: string | number,
  operators: Array<string>
}

export type FilterFront = {
  field: Field,
  value: Value | Record<string, string> | string,
  operator?: Operator | null, // AND / OR
  operation: string
}
export type FilterProcessed = { field: string, value: string | number | Array<number>, operation: string } | string
export type FilterResponse = {
  description: string,
  uid: string,
  public: boolean,
  account: {
    email: string,
    active: boolean,
    surname: string,
    approved: boolean,
    confirmed: boolean,
    name: string,
    id: number,
    role: null
  },
  query: FilterProcessed,
  locale: string,
  result_length: number,
  name: string,
}

const newDefaultFilter: FilterFront = {field: {name: '', label: '', operators: []}, value: '', operator: 'and', operation: ''};

export type AdvancedSearchProps = {
  maxResults: number | null,
  isOpen: boolean,
  advancedFilters: Array<FilterProcessed> | null,
  onAdvancedFiltersChange: (_results: Array<FilterProcessed> | null) => void,
  isWaiting: boolean
}

const AdvancedSearch: FC<AdvancedSearchProps> = ({isOpen, advancedFilters, onAdvancedFiltersChange, maxResults, isWaiting}) => {
  const {t, i18n} = useTranslation();
  const {filterUUID} = useParams();
  const {data: filterManifest} = useFetch(isOpen && `${BASE_URL_API}/filter_manifest/`);
  const [filters, setFilters] = useState<Array<FilterFront>>([]);
  const [openSaveFilterDialog, setOpenSaveFilterDialog] = useState(false);
  const [filterDetails, setFilterDetails] = useState<FilterResponse | null>(null);
  const navigate = useNavigate();
  const {user, token} = useUserSession();

  const filterApplyDisabled = useMemo(() => {
    return filters.some((filter: FilterFront) => !filter.field || !filter.value || !filter.operation);
  }, [filters]);

  // llama a pedir detalle de filtro si encuentra UUID
  const {data: filterData} = useFetch(filterUUID && API_GET_FILTER_BY_ID(filterUUID));

  useEffect(() => {
    if (filterData) {
      handleApplyFiltersFromUUID(filterData?.query);
      setFilterDetails(filterData);
    }
  }, [filterData]);

  useEffect(() => {
    // Añade nueva fila vacía al cargar el componente
    if (!filterUUID && !filters.length) handleAddNewFilterRow();
  }, [filters]);

  useEffect(() => {
    if (filterUUID && filterData && advancedFilters && filterManifest) {
      const r = processFilterToFrontForm(advancedFilters, filterManifest);
      if (r) setFilters(r);
    }
  }, [advancedFilters, filterManifest]);

  const handleAddNewFilterRow = () => {
    const newFilter = Object.assign({}, newDefaultFilter);
    const newFilters: Array<FilterFront> = [...filters, newFilter];
    setFilters(newFilters);
  };

  const handleRemoveFilterRow = (indexToRemove: number) => {
    const newFilters: Array<FilterFront> = filters.filter((filter, index) => index !== indexToRemove);
    setFilters(newFilters);
  };

  const handleFiltersValues = (filter: string, index: number, selected: { id: string | number | null } | string | null) => {
    const newFilters: Array<FilterFront> = [...filters];
    // @ts-ignore Acceder a un indice de un array con un string
    newFilters[index][filter] = selected;
    setFilters(newFilters);
  };

  const handleApplyFiltersFromUUID = (results: Array<FilterProcessed> | null) => {
    onAdvancedFiltersChange(results);
  };

  const handleApplyFilters = () => {
    const results = processFrontFilterToBackForm(filters);
    onAdvancedFiltersChange(results);
  };

  const handleClearFilters = () => {
    const newFilter = Object.assign({}, newDefaultFilter);
    setFilters([newFilter]);
    onAdvancedFiltersChange(null);
  };

  const handleSaveFilter = (filterDetails: CustomFilterForm) => {
    const newFilterToSave = {
      'name': filterDetails.name,
      'public': filterDetails.isPublic,
      'description': filterDetails.description,
      'locale': i18n.resolvedLanguage,
      'query': advancedFilters,
      'result_length': filterDetails.maxResults,
      'account': {
        id: user?.id,
      },
    };

    const options = {
      body: JSON.stringify(newFilterToSave),
      headers: {'x-csrftoken': token, 'Accept': 'application/json', 'Content-Type': 'application/json'},
      method: filterUUID ? METHODS.PUT : METHODS.POST,
    };

    const url = filterUUID ? `${BASE_URL_API}/filters/${filterUUID}/` : `${BASE_URL_API}/filters/`;
    fetch(url, options)
      .then(response => response.json())
      .then(data => {
        if (!filterUUID && data.uid) navigate(`../editfilter/${data.uid}`);
      });
  };

  return <div style={{padding: '20px', width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
    {
      filterManifest &&
      <>
        {
          filters?.map((filter: FilterFront, index: number) => {

            return <Box key={index} width={'100%'} display='flex' justifyContent='flex-end' alignItems='center' mt={1}>
              {
                index !== 0 &&
                <Box sx={{width: '14%', margin: 'auto'}}>
                  <Select
                    sx={selectStyles}
                    value={filters[index].operator}
                    onChange={(selected) => handleFiltersValues('operator', index, selected.target.value)}
                  >
                    {
                      operators.map((option) => {
                        return <MenuItem
                          key={option.value}
                          value={option.value}
                          sx={menuSelectStyles}
                        >
                          {option.label}
                        </MenuItem>;
                      })
                    }
                  </Select>
                </Box>
              }
              <FormControl sx={{width: '24%'}}>
                <Autocomplete
                  disablePortal
                  disableClearable={true}
                  value={filters[index]?.field}
                  options={Object.values(filterManifest)}
                  sx={autocompleteStyles}
                  groupBy={(option: any) => option.group}
                  onChange={(ev, selection) => handleFiltersValues('field', index, selection as Value)}
                  renderInput={(params) => {
                    return <TextField {...params} placeholder='Select field'/>;
                  }}
                />
              </FormControl>
              <Box sx={{width: '24%', pr: 1}}>
                <Select
                  sx={selectStyles}
                  value={filters[index].operation}
                  onChange={(selected) => handleFiltersValues('operation', index, selected.target.value)}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) return <div style={{color: 'slategrey'}}>Select option</div>;
                    return <div>{selected}</div>;
                  }}>
                  {
                    (filters[index]?.field.operators || []).map((option: string) => {
                      return <MenuItem
                        key={option}
                        value={option}
                        sx={menuSelectStyles}>{option}
                      </MenuItem>;
                    })
                  }
                </Select>
              </Box>
              {
                filters[index].field && filters[index].field?.type === 'free_text' &&
                
                <FormControl sx={{width: '24%'}}>
                  <TextField
                    /*@ts-ignore fixme*/
                    value={filters[index].value.id}
                    size='small'
                    variant='outlined'
                    sx={{width: '100%', '& .MuiInputBase-input': {padding: '3px 14px'}}}
                    onChange={(selected) => handleFiltersValues('value', index, {id: selected.target.value})}
                  />
                </FormControl>

                || filters[index].field && filters[index].field?.type === 'date' &&

                <Box sx={{width: '24%'}}>
                  <TextField
                    id="date-picker"
                    type="date"
                    /*@ts-ignore fixme*/
                    value={filters[index].value?.id}
                    sx={{'& .MuiInputBase-input': {padding: '3.5px 14px'}}}
                    onChange={(e) => handleFiltersValues('value', index, {id: e.target.value})}/>
                </Box>

                || filters[index].field && filters[index].field?.type === 'range' &&

                <Box sx={{width: '24%'}}>
                  <TextField
                    type="number"
                    /*@ts-ignore fixme*/
                    value={filters[index].value?.id}
                    sx={{'& .MuiInputBase-input': {padding: '3.5px 14px'}}}
                    onChange={(e) => handleFiltersValues('value', index, {id: e.target.value})}/>
                </Box>

                || filters[index].field && filters[index].field?.type === 'search' &&

                <FormControl sx={{width: '24%'}}>
                  <AutocompleteWithSearch
                    onFilterValuesChange={handleFiltersValues}
                    index={index}
                    field={filters[index].field}
                    /*@ts-ignore fixme*/
                    value={filters[index].value?.id}
                  />
                </FormControl>

                ||

                <Box sx={{width: '24%', maxWidth: '24%'}}>
                  <SelectComponent
                    filters={filters}
                    entity={'value'}
                    onFilterChange={handleFiltersValues}
                    index={index}
                  />
                </Box>
              }
              {
                <IconButton onClick={() => handleRemoveFilterRow(index)} disabled={index === 0}>
                  <DoNotDisturbOnIcon sx={{color: index === 0 ? 'white' : 'primary.main'}}/>
                </IconButton>
              }
            </Box>;
          })
        }

        <Box display='flex' justifyContent='space-between' mt={2} mr={4.5}>
          <Fab size='small' onClick={handleAddNewFilterRow} color='primary' sx={{ml: 2.5}}><AddIcon/></Fab>
          <Box>
            <Button variant='contained' sx={{mr: 2}} onClick={handleApplyFilters} disabled={filterApplyDisabled}>{t('apply')}</Button>
            {!filterUUID &&
              <Button variant='contained' sx={{mr: user ? 2 : 0}} onClick={handleClearFilters}>{t('clear')}</Button>
            }

            {user && <Button
              variant='contained'
              disabled={!advancedFilters}
              onClick={() => setOpenSaveFilterDialog(true)}>{t('save')}
            </Button>}
          </Box>

          <SaveFilterDialog
            filter={filterDetails}
            isEdit={!!filterUUID}
            isOpen={openSaveFilterDialog}
            maxResults={maxResults}
            onOpenSaveFilterDialog={setOpenSaveFilterDialog}
            onSaveFilter={handleSaveFilter}
          />
        </Box>
        { isWaiting &&
          <Box sx={loaderBoxStyles}>
            <Loader isVisible={true}/>
          </Box>}
      </>
    }
  </div>;
};

export default AdvancedSearch;
