import React, {FC, useEffect, useState} from 'react';
import BaseMapPicker from '@geomatico/geocomponents/BaseMapPicker';
import ResponsiveHeader from '@geomatico/geocomponents/ResponsiveHeader';
import SidePanel from '@geomatico/geocomponents/SidePanel';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import useMediaQuery from '@mui/material/useMediaQuery';
import RightDrawer from '../../components/RightDrawer';
import MapOverlay from '../../components/MapOverlay';
import NavBar from '../../components/header/NavBar';
import {DRAWER_WIDTH, HEADER_HEIGHT, MAPSTYLES, RIGHT_DEFAULT_DRAWER_WIDTH, SM_BREAKPOINT} from '../../config';
import {LanguagePicker} from '../../components/LanguagePicker';
import {Manifest} from '../../commonTypes';
import {FloatingLogo} from '../../components/FloatingLogo';
import LogoEjatlas from '../../components/logos/LogoEjatlas';
import {AvatarComponent} from '../../components/AvatarComponent';

export type MainProps = {
  widescreen: boolean,
  isLeftDrawerOpen: boolean
}

const Main = styled(Box)<MainProps>({
  flexGrow: 1,
  padding: 0,
  position: 'absolute',
  top: 0,
  '@media (min-width: 0px) and (orientation: landscape)': {
    top: 0
  },
  ['@media (min-width: ' + SM_BREAKPOINT + 'px)']: {
    top: 0
    //top: 64
  },
  bottom: 0,
  right: 0,
  left: 0,
  overflow: 'hidden'
}) as React.ElementType;


export type LayoutProps = {
  mainContent: React.ReactElement,
  rightSidePanelContent: React.ReactElement | null,
  leftSidePanelContent: React.ReactElement,
  mapStyle: string | null,
  onMapStyleChange?: (style: string) => void,
  onDrawerWidthChange: (_width: number) => void,
  navBarConfig: Manifest['navBarConfig'],
  availableLanguages?: Array<string>
};

const Layout: FC<LayoutProps> = (props) => {

  const {
    mainContent,
    rightSidePanelContent,
    leftSidePanelContent,
    mapStyle,
    navBarConfig,
    availableLanguages,
    onMapStyleChange,
    onDrawerWidthChange
  } = props;

  const widescreen = useMediaQuery(`@media (min-width:${SM_BREAKPOINT}px)`, {noSsr: true});
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [isRightDrawerOpen, setRightDrawerOpen] = useState(true);
  const [isHeaderOpen, setIsHeaderOpen] = useState(false);

  const handleToggle = () => {
    setRightDrawerOpen(!isRightDrawerOpen);
  };

  useEffect(() => {
    onDrawerWidthChange(isRightDrawerOpen ? RIGHT_DEFAULT_DRAWER_WIDTH : 0);
  }, [isRightDrawerOpen]);

  const handleClose = () => setIsSidePanelOpen(!isSidePanelOpen);

  const responsiveHeaderSx = {
    '& .MuiToolbar-root': {
      background: 'primary.main',
      minHeight: HEADER_HEIGHT,
      alignItems: 'center',
      pr: 1
    },
    '& .ResponsiveHeader-title': {
      p: widescreen ? 0 : '0px 16px',
      m: 0
    },
    '& .ResponsiveHeader-logo': {
      alignSelf: 'start',
      display: 'flex',
      alignItems: 'center',
      '@media screen and (min-width: 800px)': {
        display: 'none',
      },
    }
  };

  return <>
    <ResponsiveHeader
      onStartIconClick={widescreen ? undefined : handleClose}
      isStartIconCloseable={false}
      sx={responsiveHeaderSx}
      logo={<LogoEjatlas/>}
      title={!widescreen && 'EJATLAS'}
    >
      {
        widescreen
          ? <Box width='100%' display='flex' justifyContent='space-between' alignItems='center'>
            <NavBar navBarConfig={navBarConfig} onHeaderOpen={setIsHeaderOpen}/>
            <Box sx={{display: 'flex', alignItems: 'center', alignSelf: 'flex-start', height: HEADER_HEIGHT, gap: 2}}>
              <AvatarComponent/>
              <LanguagePicker availableLanguages={availableLanguages}/>
            </Box>
          </Box>
          : <Box sx={{alignSelf: 'flex-start', display: 'flex', alignItems: 'center', height: HEADER_HEIGHT}}>
            <AvatarComponent/>
          </Box>
      }
    </ResponsiveHeader>
    {leftSidePanelContent && isSidePanelOpen &&
      <SidePanel
        drawerWidth={DRAWER_WIDTH}
        anchor='left'
        isOpen={isSidePanelOpen}
        onClose={handleClose}
        widescreen={widescreen}
        sx={{'& .MuiDrawer-paper': {top: HEADER_HEIGHT, height: `calc(100vh - ${HEADER_HEIGHT}px)`}}}
      >
        {leftSidePanelContent}
      </SidePanel>
    }
    <Main widescreen={widescreen} isLeftDrawerOpen={isSidePanelOpen}>
      {mainContent}
    </Main>
    <FloatingLogo isHeaderOpen={isHeaderOpen}/>
    {mapStyle &&
      <BaseMapPicker
        position='bottom-left'
        direction='up'
        styles={MAPSTYLES}
        selectedStyleId={mapStyle}
        onStyleChange={onMapStyleChange}
      />
    }
    {mapStyle && <MapOverlay isRightDrawerOpen={isRightDrawerOpen}/>}
    {
      rightSidePanelContent &&
      <RightDrawer isOpen={isRightDrawerOpen} onToggle={handleToggle} isHeaderOpen={isHeaderOpen} isMobileMode={!widescreen}>
        {rightSidePanelContent}
      </RightDrawer>
    }
  </>;
};

export default Layout;
