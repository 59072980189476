import React, {FC} from 'react';
import Box from '@mui/material/Box';
import {ListCard, ListCardProps} from './ListCard';
import {ConflictCardInfo} from '../../commonTypes';
import {useTranslation} from 'react-i18next';
import {ENTITIES} from '../../config';

export type ListConflictsCardProps = {
  conflicts: Array<ConflictCardInfo>,
  onFilterPointByIdChange: (_id: number | null) => void,
};

export const ListConflictsCard: FC<ListConflictsCardProps> = ({conflicts, onFilterPointByIdChange}) => {
  const {t} = useTranslation();

  return <>
    {
      conflicts.length ?
        <Box mx={3} my={2}>
          {conflicts.map((result: ListCardProps, i: number) => (
            <Box onMouseEnter={() => (result.id ? onFilterPointByIdChange(result.id) : '')} key={i}>
              <ListCard
                name={result.name}
                headline={result.headline}
                image={result.image}
                slug={result.slug}
                entity={ENTITIES.CONFLICT}
              />
            </Box>
          ))
          }
        </Box>
        : <Box mt={2} p={2} fontStyle='italic' textAlign='center'>{t('noResults')}</Box>
    }
  </>;
};
