import React, {FC} from 'react';
import {useTheme} from '@mui/styles';
import {SxProps} from '@mui/system';
import {darken} from '@mui/material';
import {HEADER_HEIGHT, HEADER_HEIGHT_WITH_SUBMENU} from '../config';
import Box from '@mui/material/Box';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import {Theme} from '@mui/material';

export type DraggerProps = {
  isOpen: boolean,
  isHeaderOpen: boolean,
  onToggle: () => void,
  onResize: () => void,
  width: number,
}
const Dragger: FC<DraggerProps> = ({isOpen, isHeaderOpen, width, onResize, onToggle}) => {
  const theme: Theme = useTheme();

  const draggerSx: SxProps = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '48px',
    width: '36px',
    background: darken(theme.palette.primary.main, 0.5),
    position: 'absolute',
    right: isOpen ? width + 8 : 8,
    zIndex: 100,
    top: isHeaderOpen ? HEADER_HEIGHT_WITH_SUBMENU+8 : HEADER_HEIGHT+8,
    color: 'white',
    cursor: 'ew-resize'
  };

  return <Box onMouseDown={onResize} sx={draggerSx} onClick={onToggle}>
    {
      isOpen
        ? <KeyboardDoubleArrowRightIcon sx={{fontSize: '2rem'}}/>
        : <KeyboardDoubleArrowLeftIcon sx={{fontSize: '2rem'}}/>
    }
  </Box>;
};

export default Dragger;
