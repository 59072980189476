import React, {FC} from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {generateSection} from '../ConflictDetail';
import {Conflict} from '../../../commonTypes';

export type ConflictOutcomeProps = {
  outcome: Conflict['outcome'],
}

export const ConflictOutcome: FC<ConflictOutcomeProps> = ({outcome}) => {
  const {t} = useTranslation();
  const {project_status, suggested_alternatives, success_level, success_reason, conflict_event, other_outcomes, other_comments} = outcome;

  return <Box>
    {generateSection(t('conflictProjectStatus'), project_status?.name)}
    <Typography variant="h6" gutterBottom>
      {t('conflictOutcomeResponse')}
    </Typography>
    <Typography variant="body2" component="div" gutterBottom>
      {conflict_event.map(({name}) => <div key={name}>- {name}</div>)}
    </Typography>
    <Divider sx={{mb: 2}}/>
    {generateSection(t('conflictOtherOutcomes'), other_outcomes)}
    {generateSection(t('conflictProposalOrAlternatives'), suggested_alternatives)}
    {generateSection(t('conflictEnvironmentalSucces'), success_level)}
    {generateSection(t('conflictBriefExplanation'), success_reason)}
    {generateSection(t('conflictOtherComments'), other_comments, false)}
  </Box>;
};
