import React, {ChangeEvent, FC} from 'react';
import Box from '@mui/material/Box';
import {Checkbox} from '@mui/material';
import {Layer} from './FeaturedMapDetail';

export interface FeaturedMapLayerListProps {
  wmsLayers: Array<Layer>,
  onWmsLayersChange: (_wmsLayers: Array<Layer>) => void,
}

export const FeaturedMapLayerList: FC<FeaturedMapLayerListProps> = ({wmsLayers, onWmsLayersChange}) => {

  const handleLayerVisibility = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const layersCopy = [...wmsLayers];
    layersCopy[index].visible = event.target.checked;
    onWmsLayersChange(layersCopy);
  };

  return <Box id='layers-list'>
    {wmsLayers.map((layer, i) => {
      return <div key={layer.name}>
        <Box display='flex' alignItems='center'>
          <Checkbox onChange={(event) => handleLayerVisibility(event, i)} checked={layer.visible}/>
          <Box sx={{fontWeight: 'bold'}}>{layer.name}</Box>
        </Box>
        {layer.visible && <img src={layer.legend} alt="" style={{marginLeft: '33px'}}/>}
      </div>;
    })
    }
  </Box>;
};