import React, {FC, useEffect, useState} from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
// COMPONENTS
import {ListCard, ListCardProps} from './ListCard';
import {BASE_URL_API, ENTITIES, filters, LIMIT_CONFLICT_RESULTS} from '../../config';
// MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Loader} from '../Loader';

const beautifySlug = (slug: string | undefined) => {
  if (slug) return slug.split('-').join(' ');
};

type ConflictListFilteredProps = {
  onFilterPointByIdChange: (_id: number | null)=> void,
}

export const ConflictListFiltered: FC<ConflictListFilteredProps> = ({onFilterPointByIdChange}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {filter, slug} = useParams();

  // si en la url se añade algun filtro que no está contemplado en la API, redirecciono
  if (filter && !filters.includes(filter)) navigate('/');

  const limit = LIMIT_CONFLICT_RESULTS;
  const [offset, setOffset] = useState<number>(0);
  const [results, setResults] = useState([]);
  const [maxResults, setMaxResults] = useState<number | null>(null);
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const url = `${BASE_URL_API}/${filter}/${slug}/?limit=${limit}&offset=${offset}`;
    setIsWaiting(true);
    fetch(url).then(res => res.json()).then(res => {
      setIsWaiting(false);
      setResults(results.concat(res.results));
      setMaxResults(res.count);
      setOffset(offset + limit);
    }).catch(() => setResults([]));
  };

  const renderResults = () => (
    <Box id="conflict-list" sx={{height: '100%'}}>
      <Box sx={{mx: 3, mt: 2, mb: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <Typography variant="h5" sx={{width: '90%', fontWeight: 'bold', textTransform: 'capitalize'}}>
          { beautifySlug(slug)}
        </Typography>
      </Box>
      {(!!maxResults || maxResults === 0) &&  <Typography variant='caption' gutterBottom sx={{fontStyle: 'italic', mt: 1, mb: 2, mx: 3}}>{maxResults} {t('totalCases')}</Typography>}

      <InfiniteScroll
        dataLength={results.length}
        next={fetchData}
        hasMore={true}
        loader={<Loader isVisible={isWaiting}/>}
        height={'84vh'}
        style={{overflowX: 'hidden'}}
      >
        <Box ml={3} mt={4}>
          {results.map((result: ListCardProps, i) => (
            <Box onMouseEnter={()=> (result.id ? onFilterPointByIdChange(result.id) : '')} key={i}>
              <ListCard
                name={result.name}
                headline={result.headline}
                image={result.image}
                slug={result.slug}
                id={result.id}
                entity={ENTITIES.CONFLICT}
              />
            </Box>
          ))
          }
        </Box>
      </InfiniteScroll>
    </Box>
  );

  return <>
    {renderResults()}
  </>;
};
