import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import Map from '@geomatico/geocomponents/Map';
import {MapRef} from 'react-map-gl';
//IMPORTS
import {API_MAP_DATA, INITIAL_VIEWPORT} from '../../config';
import useFetch from '@geomatico/geocomponents/hooks/useFetch';
import {useGetColorOfPoints} from '../../hooks/useGetColorOfPoints';
import {useParams} from 'react-router-dom';
import GeoJSON, {Feature, FeatureCollection} from 'geojson';
import bbox from '@turf/bbox';
import {Marker} from '../../components/Marker';
import {PopUp} from '../../components/PopUp';
import {Manifest, PointFeature} from '../../commonTypes';
import {getIdByGroupValueName} from '../../auxiliar';
import {AnyLayer, AnySourceData, MapLayerMouseEvent} from 'mapbox-gl';
import {Layer} from '../../components/featured-map/FeaturedMapDetail';
import {ClusterPopUp} from '../../components/ClusterPopUp';
import {Loader} from '../../components/Loader';
import Box from '@mui/material/Box';
import {Dialog} from '@mui/material';

const loaderBoxStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '15vw',
  height: '12vh',
  pt: 1
};

export type MainContentProps = {
  mapStyle: string,
  wmsLayers: Array<Layer>,
  filterByLegendGroup: string,
  filterByLegendValue: string | null,
  filterPointsById: number | null,
  filterByIds: Array<number> | null,
  manifest: Manifest,
  rightDrawerWidth: number,
};

const MainContent: FC<MainContentProps> = ({mapStyle, wmsLayers, filterByLegendGroup, manifest, filterPointsById, filterByLegendValue, rightDrawerWidth, filterByIds,}) => {
  const mapRef = useRef<MapRef>(null);
  const {filter, slug} = useParams();
  const [viewport, setViewport] = useState(INITIAL_VIEWPORT);
  const [features, setFeatures] = useState<FeatureCollection | null>(null);
  const [clusteredFeatures, setClusteredFeatures] = useState<Array<GeoJSON.Feature<GeoJSON.Point>> | undefined>(undefined);
  const {data} = useFetch(API_MAP_DATA);
  if (data && !features) setFeatures(data);

  const formattedFeatures = useGetColorOfPoints(features, manifest, filterByLegendGroup);

  const [selectedFeature, setSelectedFeature] = useState<PointFeature | undefined>(undefined);
  const [hoveredFeature, setHoveredFeature] = useState<PointFeature | undefined>(undefined);

  useEffect(() => {
    if (filterPointsById) {
      // a través del id de los conflictos del listado, busca en las features y la marca como hovered
      const hoveredFeature = features?.features.find((feat: Feature) => feat.properties?.id === filterPointsById);

      setHoveredFeature(hoveredFeature as PointFeature);
    }
  }, [filterPointsById]);

  const sources = useMemo(() => {
    if (formattedFeatures && formattedFeatures.features && formattedFeatures.features?.length) {
      let newFeatures = [];
      const featuresWithoutWrongCoordinates: Array<GeoJSON.Feature> = formattedFeatures.features
        .filter((feat: PointFeature) => feat.geometry.coordinates && feat.geometry.coordinates.every((coor: number) => coor >= -180 && coor <= 180));
      if (filterByIds) {
        newFeatures = featuresWithoutWrongCoordinates
          .filter((feat: Feature) => feat.properties && filterByIds.includes(feat.properties.id));
      } else {
        const idGroupValue = getIdByGroupValueName(manifest?.legend.values, slug);
        newFeatures = featuresWithoutWrongCoordinates
          .filter((feat: Feature) =>
            filter && filter !== 'conflict' && slug ?
              filterByLegendValue ?
                feat.properties && feat.properties[filter] && feat.properties?.[filter] === idGroupValue :
                feat.properties && feat.properties[filter] && feat.properties?.[filter].includes(slug) :
              true
          );
      }

      const finalFeatures: Array<PointFeature> = newFeatures
        .map((feat: any) => {
          feat.properties = {...feat.properties, lat: feat.geometry?.coordinates[1], lng: feat.geometry?.coordinates[0]};
          return {...feat};
        });

      const clusteredFeatures = finalFeatures.filter(el => el.properties?.general);
      const unclusteredFeatures = finalFeatures.filter(el => !el.properties?.general);

      return {
        points: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: unclusteredFeatures
          },
        },
        capitals: {
          type: 'geojson',
          cluster: true,
          clusterRadius: 1,
          data: {
            type: 'FeatureCollection',
            features: clusteredFeatures
          },
        }
      };
    } else {
      return undefined;
    }
  }, [formattedFeatures, filter, slug, filterByLegendValue, filterByIds]);

  useEffect(() => {
    if (sources?.points.data.features || sources?.capitals.data.features) {
      if (filter === 'conflict') {
        let selectedFeature = sources.points.data.features.find((feat: Feature) => feat.properties?.slug === slug);
        if (!selectedFeature) selectedFeature = sources.capitals.data.features.find((feat: Feature) => feat.properties?.slug === slug);
        if (selectedFeature) {
          mapRef.current?.flyTo({
            center: [selectedFeature.properties?.lng, selectedFeature.properties?.lat],
            zoom: 9,
            padding: {
              left: 50,
              right: rightDrawerWidth + 50,
              top: 100,
              bottom: 100
            }
          });
        }
      } else if (sources?.points.data.features.length === 1) {
        const feature = sources.points.data.features[0];
        mapRef.current?.flyTo({
          center: [feature?.properties?.lng, feature?.properties?.lat],
          zoom: 9,
          padding: {
            left: 50,
            right: rightDrawerWidth + 50,
            top: 100,
            bottom: 100
          }
        });
      } else {
        if (sources?.points.data.features.length) {
          const box = bbox(sources.points.data);
          if (box[0] && box[1] && box[2] && box[3]) {
            mapRef.current?.fitBounds([[box[0], box[1]], [box[2], box[3]]],
              {
                linear: true,
                padding: {
                  left: 50,
                  right: rightDrawerWidth + 50,
                  top: 100,
                  bottom: 100
                }
              });
          }
        }
      }
    }
  }, [sources, filter, slug, rightDrawerWidth]);

  const layers = useMemo(() => {
    let computedLayers: Array<AnyLayer> = formattedFeatures ? [{
      'id': 'points',
      'source': 'points',
      'type': 'circle',
      'filter': ['!', ['has', 'multiple']],
      'maxzoom': 20,
      'paint': {
        'circle-color': ['get', 'color'],
        'circle-stroke-width': 1,
        'circle-radius': ['case',
          ['==', ['get', 'id'], hoveredFeature?.properties?.id || 0], 10,
          4],
        'circle-stroke-color': '#FFFFFF',
      }
    },
    {
      'id': 'clusters',
      'source': 'capitals',
      'type': 'circle',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#51bbd6',
          1,
          '#f1f075',
          5,
          '#f28cb1'
        ],
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          5,
          1,
          8,
          5,
          10
        ]
      }
    },
    {
      id: 'cluster-count',
      type: 'symbol',
      source: 'capitals',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-font': ['Arial-Regular'],
        'text-size': 10
      }
    }
    ] : [];
    if (wmsLayers.length) {
      const layers: Array<AnyLayer> = wmsLayers.filter(l => l.visible).map((layer) => {
        return {
          'id': `wms-layer-${layer.layer_name}`,
          'type': 'raster',
          'source': `wms-source-${layer.layer_name}`,
          'paint': {}
        };
      });
      computedLayers = layers.concat(computedLayers);
    }
    return computedLayers;
  }, [formattedFeatures, hoveredFeature, wmsLayers]);

  const handleOnClick = (e: MapLayerMouseEvent) => {

    if (e.features?.[0]?.layer.id === 'clusters') {
      const cluster = mapRef.current?.queryRenderedFeatures(e.point, {layers: ['clusters']});
      const clusterSource = mapRef.current?.getSource('capitals');
      const cluster_id = cluster?.[0].properties?.cluster_id;
      // @ts-ignore FIXME cual tipo correcto de clusterSource?
      clusterSource?.getClusterLeaves(cluster_id, 100, 0, (error, features) => {
        setClusteredFeatures(undefined);
        if (!error) setClusteredFeatures(features);
      });
    } else {
      setClusteredFeatures(undefined);
      setSelectedFeature(undefined);
      const x = e.features?.length ? e.features[0] : undefined;
      setSelectedFeature(x as PointFeature);
    }

  };

  const handleOnHover = (e: MapLayerMouseEvent) => {
    if (!e?.features?.length) return setHoveredFeature(undefined);
    if (e?.features?.[0].properties?.id !== hoveredFeature?.properties?.id) {
      const newHoveredFeature = e.features[0];
      setHoveredFeature(newHoveredFeature as PointFeature);
    }
  };

  const wmsSources = useMemo(() => {
    const extraParams = '&bbox={bbox-epsg-3857}&format=image/png&srs=EPSG:3857&transparent=true&width=256&height=256';
    const wmsSources: Record<string, AnySourceData> = {};

    wmsLayers.filter(l => l.visible).forEach((layer) => {
      const name = `wms-source-${layer.layer_name}`;
      wmsSources[name] = {
        'type': 'raster',
        'tiles': [decodeURI(layer.url + extraParams)],
        'tileSize': 256
      };
    });
    return wmsLayers.length ? wmsSources : {};
  }, [wmsLayers]);

  return <>
    <Map
      ref={mapRef}
      mapStyle={mapStyle}
      viewport={viewport}
      sources={wmsLayers.length ? {...sources, ...wmsSources} : {...sources}}
      layers={[...layers]}
      onViewportChange={setViewport}
      attributionControl={false}
      onClick={handleOnClick}
      onMouseMove={handleOnHover}
      interactiveLayerIds={layers.length ? ['points', 'clusters'] : []}
    >
      <Marker feature={selectedFeature}/>
      <Marker feature={hoveredFeature}/>
      <PopUp feature={selectedFeature} onFeatureChange={setSelectedFeature}/>
      {
        clusteredFeatures &&
      <ClusterPopUp features={clusteredFeatures} onFeatureChange={setSelectedFeature} onClusteredFeaturesChange={setClusteredFeatures}/>
      }
    </Map>
    <Dialog open={sources === undefined} onClose={() => console.log()}>
      <Box sx={loaderBoxStyles}>
        <Loader isVisible={sources === undefined}/>
      </Box>
    </Dialog>
  </>;
};

export default MainContent;