import React, {FC} from 'react';
import Box from '@mui/material/Box';
import {useTranslation} from 'react-i18next';
import {generateSection} from '../ConflictDetail';
import {Conflict} from '../../../commonTypes';

export type ConflictMetainformationProps = {
  metainformation: Conflict['metainformation']
}

export const ConflictMetainformation: FC<ConflictMetainformationProps> = ({metainformation}) => {
  const {t} = useTranslation();
  const {contributor, last_update, conflict_id} = metainformation;

  return <Box>
    {generateSection(t('conflictContributor'), contributor)}
    {generateSection(t('conflictLastUpdate'), last_update)}
    {generateSection(t('conflictId'), conflict_id)}
  </Box>;
};
