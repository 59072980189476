import React, {useEffect, useState} from 'react';
import Layout from './Layout';
import MainContent from './MainContent';
import LeftSidePanelContent from './LeftSidePanelContent';
import RightSidePanelContent from './RightSidePanelContent';
import useFetch from '@geomatico/geocomponents/hooks/useFetch';
import {API_MANIFEST, INITIAL_MAPSTYLE_URL, RIGHT_DEFAULT_DRAWER_WIDTH} from '../../config';
import LegendSelector from '../../components/LegendSelector';
import {useNavigate, useParams} from 'react-router-dom';
import {Layer} from '../../components/featured-map/FeaturedMapDetail';
import i18n from 'i18next';


const Index = () => {

  const [rightDrawerWidth, setRightDrawerWidth] = useState(RIGHT_DEFAULT_DRAWER_WIDTH);
  const [mapStyle, setMapStyle] = useState(INITIAL_MAPSTYLE_URL);
  const [filterByLegendGroup, setFilterByLegendGroup] = useState<string>('category'); // cambia la leyenda( todos los colores de los punts)// simbolizacion seleccionada
  const [filterByLegendValue, setFilterByLegendValue] = useState<string | null>(null); //filtra por categorias de la leyenda
  const [filterPointsById, setFilterPointsById] = useState<number | null>(null); // id de hover sobre el elemento en la lista
  const [filterPointsByIds, setFilterPointsByIds] = useState<Array<number> | null>(null); // ids de conflicts devueltos por la api en búsqueda por texto
  const [wmsLayers, setWmsLayers] = useState<Array<Layer>>([]);

  const navigate = useNavigate();
  const {filter} = useParams();

  useEffect(() => {
    if (!filter) {
      setFilterByLegendValue(null);
      if (wmsLayers.length) setWmsLayers([]); // resetea wmsLayers cuando se usa el ir atrás en el navegador
    }
  }, [filter]);

  useEffect(() => {
    if (filterByLegendValue) navigate(`../${filterByLegendGroup}/${filterByLegendValue}`);
  }, [filterByLegendValue]);

  const {data: manifest} = useFetch(API_MANIFEST);

  useEffect(()=> {
    if(manifest?.default_language && i18n.resolvedLanguage !== manifest?.default_language) {
      i18n.changeLanguage(manifest.default_language);
    }
  },[manifest]);

  const legend = manifest?.legend.values;

  const leftSidePanelContent = <LeftSidePanelContent navBarConfig={manifest?.navBarConfig}/>;
  const mainContent = <MainContent
    mapStyle={mapStyle}
    wmsLayers={wmsLayers}
    filterByLegendGroup={filterByLegendGroup}
    manifest={manifest}
    filterPointsById={filterPointsById}
    filterByLegendValue={filterByLegendValue}
    rightDrawerWidth={rightDrawerWidth}
    filterByIds={filterPointsByIds}
  />;

  const rightSidePanelContent = <RightSidePanelContent
    onFilterPointByIdChange={setFilterPointsById}
    onFilterByIds={setFilterPointsByIds}
    wmsLayers={wmsLayers}
    onWmsLayersChange={setWmsLayers}
  >
    {legend && <LegendSelector
      legend={legend}
      filterByLegendGroup={filterByLegendGroup}
      filterByLegendValue={filterByLegendValue}
      onFilterByLegendGroupChange={setFilterByLegendGroup}
      onFilterByLegendValueChange={setFilterByLegendValue}
    />}
  </RightSidePanelContent>;

  return <>
    <Layout
      leftSidePanelContent={leftSidePanelContent}
      mainContent={mainContent}
      rightSidePanelContent={rightSidePanelContent}
      onDrawerWidthChange={setRightDrawerWidth}
      mapStyle={mapStyle}
      onMapStyleChange={setMapStyle}
      navBarConfig={manifest?.navBarConfig}
      availableLanguages={manifest?.available_languages}
    />
  </>;
};

export default Index;