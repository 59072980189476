import React, {FC, ReactNode, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import {darken, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {MINI_SIDE_PANEL_WIDTH, ROLES} from '../../config';
import {Manifest} from '../../commonTypes';
import {useUserSession} from '../../hooks/useUserSession';

interface TabPanelProps {
  children?: Array<ReactNode>;
  dir?: string;
  index: number;
  value: number;
}

const everyone = [
  {
    'name': 'featured',
    'url': '/featured',
    'submenu': []
  },  {
    'name': 'donate',
    'url': 'https://micromecenatge.uab.cat/EJAtlas_en ',
    'submenu': []
  }
];

const collaborator = [
  {
    'name': 'myCases',
    'url': '/backoffice/conflicts/list_conflicts',
    'submenu': []
  },
  {
    'name': 'newCase',
    'url': '/backoffice/conflicts/create_conflict',
    'submenu': []
  }
];
const administrator = [
  {
    'name': 'users',
    'url': '/backoffice/user/list_users',
    'submenu': []
  },
  {
    'name': 'filters',
    'url': '/backoffice/conflicts/list_filters',
    'submenu': []
  }
];

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;
  const theme = useTheme();

  return (
    <Box
      role='tabpanel'
      hidden={value !== index || !children?.length}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      sx={{
        pt: 0.25,
        pb: 0,
        pr: 0,
        pl: 2,
        ml: MINI_SIDE_PANEL_WIDTH + 'px',
        bgcolor: darken(theme.palette.primary.main, 0.5),
        height: 36,
        width: 'fit-content'
      }}
      {...other}
    >
      {
        value === index && <Box sx={{p: 0}}>
          <Typography>{children}</Typography>
        </Box>
      }
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

interface NavBarProps {
  navBarConfig: Manifest['navBarConfig'],
  onHeaderOpen: (_open: boolean) => void
}

const NavBar: FC<NavBarProps> = ({navBarConfig, onHeaderOpen}) => {
  const {t} = useTranslation();
  const {user} = useUserSession();

  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [menu, setMenu] = useState<Manifest['navBarConfig']>([]);

  useEffect(() => {
    if (navBarConfig) {
      let newMenu: Manifest['navBarConfig'] = [];
      const filteredMenu: Manifest['navBarConfig'] = [...menu];
      const home = navBarConfig[0];
      const restMenu = navBarConfig.slice(1, navBarConfig.length);
      if (user && user.account_type === ROLES.COLLABORATOR) {
        newMenu = filteredMenu
          .concat(home)
          .concat(restMenu)
          .concat(collaborator);
      } else if (user && user?.account_type === ROLES.ADMINISTRATOR || user && user?.account_type === ROLES.MODERATOR) {
        newMenu = filteredMenu
          .concat(home)
          .concat(restMenu)
          .concat(collaborator)
          .concat(administrator);
      } else {
        newMenu = filteredMenu
          .concat(home)
          .concat(everyone)
          .concat(restMenu);
      }
      setMenu(newMenu);
    }
  }, [navBarConfig]);


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleMenuClick = (url: string | undefined, hasSubmenu: boolean) => {
    if (url) window.location.replace(url);
    onHeaderOpen(hasSubmenu);
  };

  const tabsSx = {
    ml: 2,
    '& .MuiTab-root': {
      padding: '12px 8px',
      color: 'common.white'
    },
    '& .MuiTabs-indicator': {
      backgroundColor: darken(theme.palette.primary.main, 0.50),
      height: 50,
    },
    '& .MuiTab-root.Mui-selected': {
      color: 'common.white',
      fontWeight: 900,
      zIndex: 5000
    }
  };

  return <Box sx={{width: '100%'}}>
    <Tabs value={value} onChange={handleChange} sx={tabsSx}>
      {menu?.map((menuItem, i: number) => {
        return <Tab onClick={() => handleMenuClick(menuItem?.url, !!menuItem.submenu?.length)} key={menuItem.name} label={t(menuItem.name)} {...a11yProps(i)}/>;
      })
      }
    </Tabs>
    {
      menu?.map((menuItem, i: number) => {
        return <TabPanel key={menuItem.name + i} value={value} index={i} dir={theme.direction}>
          {
            menuItem.submenu.map((submenu) => {
              return <Button
                onClick={() => handleMenuClick(submenu.url, !!menuItem.submenu?.length)}
                key={submenu.key}
                sx={{mr: 2, textTransform: 'none', color: 'common.white'}}
                variant="text">{t(submenu.name)}</Button>;
            })
          }
        </TabPanel>;
      })
    }
  </Box>;
};

export default NavBar;
