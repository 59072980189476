import React, {FC} from 'react';
//MUI
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

import {useNavigate, useSearchParams} from 'react-router-dom';
import {ENTITIES} from '../../config';
import {SxProps} from '@mui/system';

export type ListCardProps = {
  name: string,
  headline: string,
  image: string,
  slug: string,
  id?: number,
  entity?: string,
};

const headLineStyles: SxProps = {
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const ListCard: FC<ListCardProps> = ({name, headline, image, slug, entity = ENTITIES.CONFLICT}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleClick = () => {
    const translate = searchParams.get('translate');
    navigate(`../${entity}/${slug}${translate ? ('?translate=' + translate) : ''}`);
  };

  return <>
    <Card onClick={handleClick} sx={{display: 'flex', boxShadow: 0}}>
      <CardMedia
        component="img"
        image={image || '/images/no-image.jpeg'}
        sx={{width: '120px', minWidth: '120px', height: '90px', paddingY: 1}}
      />
      <CardContent sx={{paddingY: 1, paddingRight: 0, cursor: 'pointer', lineHeight: '15px'}}>
        <Typography variant="body1" component="div" sx={{fontWeight: 'bold', color: '#0380a5'}}>
          {name}
        </Typography>
        <Typography sx={headLineStyles} variant="caption" color="text.secondary">
          {headline}
        </Typography>
      </CardContent>
    </Card>
  </>;
};
