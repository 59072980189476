import ReactDOM from 'react-dom';
import React, {FC} from 'react';
import Routes from './routes/routes';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import {StyledEngineProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';

import './i18n';
import * as Sentry from '@sentry/react';
import CookieBanner from 'react-cookie-banner';
import {useTranslation} from 'react-i18next';

const styles = {
  banner: {
    position: 'absolute',
    bottom: 0,
  },
  message: {
    display: 'block',
    padding: '5px 67px',
    lineHeight: 1.3,
    textAlign: 'left',
    marginRight: 60,
    color: 'white'
  },

  link: {
    fontWeight: 'bold'
  }
};
if (process.env.SENTRY_ENVIRONMENT === 'production' && process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: 'production',
    integrations: [new Sentry.BrowserTracing({tracePropagationTargets: ['*']}), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 0.5,
    // Session Replay
    //replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    //replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const App: FC = () => {

  const {t, i18n} = useTranslation();
  const toURL = '/backoffice/cms/${i18n.resolvedLanguage}/privacy/';

  return <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme()}>
      <CssBaseline/>
      <Routes/>

      <CookieBanner
        styles={styles}
        message={t('cookie')}
        link={<a href={toURL} rel="noreferrer"  target='_blank'>{t('moreInformation')}</a>}
        buttonMessage={t('close')}
      />
    </ThemeProvider>
  </StyledEngineProvider>;
};
const target = document.getElementById('app');
if (target) ReactDOM.render(<App/>, target);

export default App;
