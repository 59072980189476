import React, {FC, ReactNode, useEffect, useState} from 'react';
import {BASE_URL_API, filters} from '../../config';
//COMPONENTS
import {ConflictList} from '../../components/conflict-list/ConflictList';
import {ConflictDetail} from '../../components/conflict-detail/ConflictDetail';
import {ConflictListFiltered} from '../../components/conflict-list/ConflictListFiltered';
// Hooks
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import useFetch from '@geomatico/geocomponents/hooks/useFetch';
import {FeaturedMapDetail, Layer} from '../../components/featured-map/FeaturedMapDetail';

type RightSidePanelContentProps = {
  onFilterPointByIdChange: (_id: number | null) => void,
  onFilterByIds: (_conflictsIdsToFilter: Array<number> | null) => void
  children: ReactNode,
  onWmsLayersChange: (_wmsLayers: Array<Layer>) => void,
  wmsLayers: Array<Layer>,
}

const RightSidePanelContent: FC<RightSidePanelContentProps> = ({onFilterPointByIdChange, onFilterByIds, wmsLayers, onWmsLayersChange, children}) => {
  const {filter, slug} = useParams();
  const {i18n} = useTranslation();
  const navigate = useNavigate();
  const isFiltered = filter && filters.includes(filter);
  const isConflictDetail = filter === 'conflict';
  const isFeaturedMap = filter === 'featured';
  const [conflictLang, setConflictLang] = useState<string>(i18n.resolvedLanguage);

  const handleLinkClick = (filter: string, slug: string) => navigate(`../${filter}/${slug}`);

  const {data: conflictdetail} = useFetch(slug && filter === 'conflict' && `${BASE_URL_API}/conflicts/${slug}/${conflictLang}`);

  useEffect(() => {
    //Esto provoca que cuando se cambia el lang de la app, rehaga la peticion con el nuevo lang
    setConflictLang(i18n.resolvedLanguage);
  }, [i18n.resolvedLanguage]);


  return <>
    {isFeaturedMap
      ? <FeaturedMapDetail wmsLayers={wmsLayers} onWmsLayersChange={onWmsLayersChange} onFilterByIds={onFilterByIds}/>
      : isConflictDetail
        ? conflictdetail && <ConflictDetail
          conflict={conflictdetail}
          onLinkClick={handleLinkClick}
          onConflictLangChange={setConflictLang}
        />
        : (
          isFiltered ?
            <ConflictListFiltered onFilterPointByIdChange={onFilterPointByIdChange}/> :
            <ConflictList onFilterPointByIdChange={onFilterPointByIdChange} onFilterByIds={onFilterByIds}>{children}</ConflictList>
        )
    }
  </>;
};

export default RightSidePanelContent;
