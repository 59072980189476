import {useEffect, useState} from 'react';
import  {Feature, FeatureCollection} from 'geojson';
import {LegendGroup, LegendValue, Manifest} from '../commonTypes';

export const useGetColorOfPoints = (features: FeatureCollection | null, manifest: Manifest, legendGroupName: string) => {
  const [featuresWithColors, setFeaturesWithColors] = useState<any | undefined>();
  const selectedLegendGroup: LegendGroup | undefined = manifest?.legend.values.find((el: LegendGroup) => el.name === legendGroupName);

  useEffect(() => {
    if (features && manifest && legendGroupName) {
      const newFeatures = features?.features.map((feat: Feature) => {
        const legend = selectedLegendGroup?.values.find((g: LegendValue) => g.id === feat.properties?.[selectedLegendGroup.name]);
        feat.properties = {
          ...feat.properties,
          color: legend?.color || 'red',
          legend_slug: legend?.slug,
        };
        return feat;
      });

      const copyFeatures = {...features};
      copyFeatures.features = newFeatures;
      setFeaturesWithColors(copyFeatures);
    }
  }, [features, manifest, legendGroupName]);

  return featuresWithColors;
};