import React, {FC} from 'react';
import PropTypes from 'prop-types';

//MUIn
import Box from '@mui/material/Box';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import styled from '@mui/styles/styled';
import {Theme} from '@mui/material';

//COMPONENTS
import SelectInput from '@geomatico/geocomponents/SelectInput';
import {legendIconsMap} from '../config';
import {LegendGroup, LegendValue} from '../commonTypes';

const selectStyles = {
  mt: 1,
  mr: 1,
  alignItems: 'flex-end',
  '& .SelectInput-select': {
    bgcolor: 'white',
    fontSize: '12px',
    width: '130px',
  },
  '& .MuiSelect-select': {
    padding: '3.5px 14px',
  },
};

const menuSelectStyles = {
  '& .MuiMenuItem-root': {
    fontSize: '12px',
  }
};

const Legend = styled(Box)<Theme>(({theme}) => ({
  backgroundColor: 'white',
  borderRadius: '3px',
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const LegendItem = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  fontSize: '12px',
  '&:hover': {
    fontWeight: 'bold',
  }
});

export const LegendIcon: FC<{ color: string, slug: string, size?: number, selected?: boolean }> = ({color, slug, selected = false}) => {
  const hasIcon = Object.keys(legendIconsMap).includes(slug);
  const sxWithoutIcon = {
    fontSize: `${32}px`,
    borderRadius: '26px',
    marginRight: 1,
    height: '32px',
    width: '32px',
    transform: 'scale(0.5)',
    margin: '-5px 0px -7px 0px',
    border: selected ? '2px solid black' : '0px solid black',
    background: color,
    color: hasIcon ? 'transparent' : color,
  };
  const sxWithIcon = {
    ...sxWithoutIcon,
    background: hasIcon ? `${color} url(/images/ikon.png) no-repeat ${legendIconsMap[slug]}px center` : color,
  };

  const styles = hasIcon ? sxWithIcon : sxWithoutIcon;
  const border = selected ? {border: '2px solid black'} : {};
  return <FiberManualRecordIcon sx={{...styles, border}}/>;
};

LegendIcon.propTypes = {color: PropTypes.string.isRequired};

export type LegendSelectorProps = {
  legend: Array<LegendGroup>,
  filterByLegendGroup: string,
  filterByLegendValue: string | null,
  onFilterByLegendValueChange: (_legendValueSlug: string | null) => void,
  onFilterByLegendGroupChange: (_legendGroupName: string)=> void,
}

export const LegendSelector: FC<LegendSelectorProps> = ({filterByLegendValue, onFilterByLegendValueChange, legend, filterByLegendGroup, onFilterByLegendGroupChange}) => {

  const selectedLegend = legend.filter(option => option.name === filterByLegendGroup);
  const options = legend.map(el => ({id: el.name, label: el.label}));

  const handleOnClick =(slug: string | null)=> {
    onFilterByLegendValueChange(filterByLegendValue && filterByLegendValue === slug ? null : slug);
  };

  const handleOnOptionChange = (name: string)=> {
    onFilterByLegendValueChange(null);
    onFilterByLegendGroupChange(name);
  };

  return <Box display='flex' flexDirection={'column-reverse'} justifyContent='space-between'>
    <Legend>
      {selectedLegend?.length && selectedLegend[0].values.map(({id, color, label, slug}: LegendValue) =>
        <LegendItem onClick={()=> handleOnClick(slug)} key={id}>
          <LegendIcon color={color} slug={slug || ''} selected={!filterByLegendValue || slug === filterByLegendValue}/>
          {label}
        </LegendItem>
      )}
    </Legend>

    <SelectInput
      options={options}
      selectedOptionId={filterByLegendGroup}
      onOptionChange={(name: string)=>handleOnOptionChange(name)}
      sx={selectStyles}
      menuSx={menuSelectStyles}
    />
  </Box>;
};

export default LegendSelector;
