import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import useFetch from '@geomatico/geocomponents/hooks/useFetch';
import Box from '@mui/material/Box';
import {ListCard, ListCardProps} from './ListCard';
import {API_GET_FEATURED_MAP_LIST, ENTITIES} from '../../config';

export const ListFeaturedMapCard: FC= () => {
  const {t} = useTranslation();
  const maxResults = 2;
  const offset = 0;

  const {data} = useFetch(API_GET_FEATURED_MAP_LIST(maxResults, offset));

  return <>
    {
      data?.results.length ?
        <Box mx={3} my={2}>
          { data?.results.map((result: ListCardProps, i: number) => (
            <Box  key={i}>
              <ListCard
                name={result.name}
                headline={result.headline}
                image={result.image}
                slug={result.slug}
                entity={ENTITIES.FEATURED}
              />
            </Box>
          ))
          }
        </Box>
        : <Box mt={2} p={2} fontStyle='italic' textAlign='center'>{t('noResults')}</Box>
    }
  </>;
};
