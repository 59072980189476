import React, {FC} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {useTranslation} from 'react-i18next';
import {generateSection} from '../ConflictDetail';
import {Conflict} from '../../../commonTypes';
import Divider from '@mui/material/Divider';

export type ConflictSourceProps = {
  source: Conflict['source']
  onLinkClick: (_filter: string, _value: string) => void,
}

export const ConflictSource: FC<ConflictSourceProps> = ({source, onLinkClick}) => {
  const {t} = useTranslation();
  const {type1, type2, products, other_commodities, other_types} = source;

  return <Box>
    {generateSection(t('conflictType1'), type1.name)}
    <Typography variant="h6" gutterBottom>
      {t('conflictType2')}
    </Typography>
    {
      type2.map((type, i) => <Typography key={type.name+i} variant="body2" component="div" gutterBottom sx={{mb: 2}}>
        {type.name}
      </Typography>
      )
    }
    <Divider sx={{mb: 2}}/>
    {generateSection(t('conflictOtherTypes'), other_types)}

    <Typography variant="h6" gutterBottom>
      {t('specificCommodities')}
    </Typography>
    {
      products.map((product, i) => {
        return <Link key={product.slug + i} color='secondary' onClick={() => onLinkClick('commodity', product.slug)} sx={{cursor: 'pointer'}}>
          <Typography variant="body2" component="div" gutterBottom>
            {product.name}<br/>
          </Typography>
        </Link>;
      })
    }
    {
      other_commodities?.map((commodity, i) => {
        return <Typography key={i} variant="body2" component="div" gutterBottom>
          {commodity}
        </Typography>;
      })
    }
  </Box>;
};
