import React, {FC, ReactNode} from 'react';
import PropTypes from 'prop-types';

//MUI
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import Dragger from './Dragger';

import {HEADER_HEIGHT, HEADER_HEIGHT_WITH_SUBMENU, RIGHT_DEFAULT_DRAWER_WIDTH} from '../config';
import {useTheme} from '@mui/styles';
import {darken, Theme} from '@mui/material';
import {SxProps} from '@mui/system';
import useResize from '../hooks/useResize';
import {useParams} from 'react-router-dom';


//STYLES
const drawerContentStyle = {
  py: 0,
  px: 0,
  width: '100%',
};

const container = window !== undefined ? () => window.document.body : undefined;

export type RightDrawerProps = {
  isOpen: boolean,
  isHeaderOpen: boolean,
  isMobileMode: boolean,
  onToggle: () => void,
  children: ReactNode,
}

const RightDrawer: FC<RightDrawerProps> = ({isOpen, isHeaderOpen, isMobileMode, onToggle, children}) => {
  const handleDrawerClose = () => onToggle && onToggle();
  const theme: Theme = useTheme();
  // @ts-ignore ...
  const {slug} = useParams();
  const {width, enableResize} = useResize({minWidth: RIGHT_DEFAULT_DRAWER_WIDTH});

  //STYLES
  const drawerStyle: SxProps = {
    '& .MuiDrawer-paper': {
      overflowY: slug ? 'scroll' : 'hidden',
      top: isMobileMode ? HEADER_HEIGHT : (isHeaderOpen ? HEADER_HEIGHT_WITH_SUBMENU : HEADER_HEIGHT),
      bottom: 20,
      width: width,
      maxWidth: '100vw',
      p: 0
    },
    '& #dragger-mobile-close': {
      position: 'absolute',
      top: 8,
      right: 0
    }
  };

  return <Box id='right-drawer'>
    <Dragger width={width} onResize={enableResize} isHeaderOpen={isHeaderOpen} isOpen={isOpen} onToggle={onToggle}/>
    <Hidden mdUp implementation="js">{/*MOBILE*/}
      <Drawer
        id='drawer-custom-mobile'
        sx={drawerStyle}
        ModalProps={{keepMounted: true}}// Better open performance on mobile.
        variant="temporary"
        container={container}
        anchor="right"
        open={isOpen}
        onClose={handleDrawerClose}
      >
        <Box
          id='dragger-mobile-close'
          onClick={onToggle}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mr: 1,
            height: '48px',
            width: '36px',
            background: darken(theme.palette.primary.main, 0.5)
          }
          }>
          <KeyboardDoubleArrowRightIcon onMouseDown={enableResize} sx={{fontSize: '2rem', color: 'common.white'}}/>
        </Box>

        <Box sx={drawerContentStyle}>
          {children}
        </Box>
      </Drawer>
    </Hidden>
    <Hidden mdDown implementation="css">{/*DESKTOP*/}
      <Drawer
        PaperProps={{style: {width}}}
        id='drawer-custom-desktop'
        sx={drawerStyle}
        variant="persistent"
        anchor="right"
        open={isOpen}
      >
        <Box sx={drawerContentStyle}>
          {children}
        </Box>
      </Drawer>
    </Hidden>
  </Box>;
};

RightDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
export default RightDrawer;