import React, {FC} from 'react';
import Box from '@mui/material/Box';
import {useTranslation} from 'react-i18next';
import {generateSection} from '../ConflictDetail';
import {Conflict, GroupImpact} from '../../../commonTypes';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

export type ConflictImpactsProps = {
  impacts: Conflict['impacts'],
}

export const ConflictImpacts: FC<ConflictImpactsProps> = ({impacts}) => {
  const {t} = useTranslation();
  const {
    environmental_impact, other_environmental_impacts,
    health_impact, other_health_impacts,
    socio_economical_impact, other_socio_economical_impacts
  } = impacts;
  const impactSection = (group_impact: GroupImpact) =>
    <>
      {
        group_impact?.visible.length ? <>
          <Typography variant="subtitle1" gutterBottom sx={{fontWeight: 'bold'}}>
            {t('visibleImpact')}
          </Typography>
          <Typography variant="body2" component="div" gutterBottom>
            {group_impact?.visible.map(({name}) => <div key={name}>- {name}</div>)}
          </Typography>
        </> : undefined
      }

      {
        group_impact?.potential.length ? <>
          <Typography variant="subtitle1" gutterBottom sx={{fontWeight: 'bold'}}>
            {t('potentialImpact')}
          </Typography>
          <Typography variant="body2" component="div" gutterBottom>
            {group_impact?.potential.map(({name}) => <div key={name}>- {name}</div>)}
          </Typography>
        </> : undefined
      }
    </>;

  return <Box>

    <Typography variant="h6" gutterBottom>
      {t('conflictEnvironmentalImpact')}
    </Typography>
    { environmental_impact && impactSection(environmental_impact) }
    <Divider sx={{my: 2}}/>

    {generateSection(t('conflictOtherEnvImpacts'), other_environmental_impacts)}

    <Typography variant="h6" gutterBottom>
      {t('conflictHealthImpact')}
    </Typography>
    { health_impact && impactSection(health_impact) }
    <Divider sx={{my: 2}}/>

    {generateSection(t('conflictOtherHltImpacts'), other_health_impacts)}

    <Typography variant="h6" gutterBottom>
      {t('conflictSocioeconomicalImpact')}
    </Typography>

    { socio_economical_impact && impactSection(socio_economical_impact) }
    <Divider sx={{my: 2}}/>

    {generateSection(t('conflictOtherSecImpacts'), other_socio_economical_impacts, false)}

  </Box>;
};
