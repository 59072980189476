import React, {FC} from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import {useTranslation} from 'react-i18next';
import {generateSection} from '../ConflictDetail';
import {Conflict} from '../../../commonTypes';

export type ConflictBasicDataProps = {
  name: string,
  basicData: Conflict['basic_data'],
  onLinkClick: (_filter: string, _value: string) => void,
}

export const ConflictBasicData: FC<ConflictBasicDataProps> = ({name, basicData, onLinkClick}) => {
  const {t} = useTranslation();
  const {country, province, location, accuracy_level} = basicData;

  return <>
    {generateSection(t('conflictName'), name)}
    <Typography variant="h6" gutterBottom>
      {t('conflictCountry')}
    </Typography>
    <Link color='secondary' onClick={() => onLinkClick('country', country.slug)} sx={{cursor: 'pointer'}}>
      <Typography variant="body2" component="div" gutterBottom sx={{mb: 2}}>
        {country.name}
      </Typography>
    </Link>
    <Divider sx={{mb: 2}}/>

    {generateSection(t('conflictState'), province)}
    {generateSection(t('conflictLocation'), location)}
    {generateSection(t('conflictAccuracy'), accuracy_level, false)}
  </>;
};
