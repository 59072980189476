import React, {FC, SVGProps} from 'react';

const LogoEjatlas: FC<SVGProps<SVGSVGElement>> = (props) =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 234.323 360.57"
    height={44}
    xmlSpace="preserve"
    {...props}
  >
    <g fillRule="evenodd" clipRule="evenodd">
      <path
        fill="#024357"
        d="M165.653 255.974c4.922 2.715 9.996 5.188 14.739 8.186 30.834 19.486 31.095 47.83 1.052 68.797-15.492 10.813-33.8 13.729-51.066 19.613H96.232c-5.135-3.256-10.827-.855-16.218-1.6-18.778.77-35.363-5.164-50.358-16.211-23.395-17.236-25.008-45.328-3.285-64.824 10.077-9.043 21.795-15.496 35.073-18.82 6.202-.973 11.144 1.76 15.786 5.361 14.873 12.031 32.322 17.309 50.965 19.797 22.505 3.006 27.024.332 33.116-20.744 1.547-.779 2.994-.631 4.342.445z"
      />
      <path
        fill="#0A81A4"
        d="M5.793 97.702C11.474 55.964 32.388 25.365 71.397 8h3.104c8.093 9.874 3.521 20.872 2.805 31.527-1.266 2.98-3.588 4.266-6.742 4.217-12.014-4.799-16.735 4.223-22.215 11.784-8.552 9.346-15.726 19.847-24.409 29.078-6.048 6.196-7.728 15.8-15.849 20.378-3.868-1.45-3.731-4.163-2.298-7.282z"
      />
      <path
        fill="#FDA653"
        d="M0 129.065a5.263 5.263 0 0 0 2.87-.352c7.728 12.75 7.198 25.464-.16 38.151a9.108 9.108 0 0 1-2.71-.549v-37.25z"
      />
      <path
        fill="#3E6F7E"
        d="M80.71 352.57c.353-1.016.203-1.951-.453-2.805 5.587-.561 11.319-1.943 15.975 2.805H80.71z"
      />
      <path
        fill="#FD8918"
        d="m2.71 166.865.16-38.151.813-18.21c1.039-2.868 3.104-4.957 5.271-6.969 3.575-7.458 6.825-15.118 12.979-20.981 2.498-.879 3.742.594 4.752 2.516 1.258 4.372-.257 9.21 2.221 13.396-.258 5.02-4.979 7.378-6.816 11.45-1.883 2.389-4.818 3.701-6.462 6.224.346.813-2.374 1.3-.475 2.134 1.85.812-1.07.521.406-1.104 1.54-3.001 4.411-4.617 6.385-7.048 4.099-4.653 7.036-10.071 10.084-15.427 2.477.176 3.758 1.786 4.673 3.85 2.188 7.927.053 17.067 6.663 23.767-.438 11.532-2.93 23.449 6.271 33.167 3.964 5.742 2.68 15.979 13.57 15.682 2.022-.437 3.802-1.416 5.57-2.44 10.423-7.815 22.121-5.96 33.767-4.532 8.831.403 13.581 5.266 15.951 13.603 2.531 8.9 6.579 17.064 15.15 21.951 1.669 5.344-2.438 7.711-5.465 10.643-4.815 1.887-10.063 3.568-4.1 9.941 3.027 3.23 5.822 6.641 7.726 10.682 3.541 6.754 8.102 11.824 16.743 10.201 5.293-1.328 10.015-3.98 14.871-6.309 5.104-1.949 9.587-5.195 14.691-7.121 7.134-2.072 7.729-7.818 7.747-13.883.057-1.381.258-2.729.551-4.068.336-1.338.865-2.596 1.533-3.797 3.426-4.156 7.667-7.402 11.95-10.598 2.209-1.315 4.318-2.758 5.816-4.936 3.495-5.811 6.186-12.007 9.05-18.109 1.727-3.604 3.019-7.455 5.622-10.589 1.628-2.022 3.231-4.068 4.077-6.587 1.55-8.425-8.585-12.687-7.462-20.914-.723-3.368-1.238-6.742.632-9.95 1.734-2.865 2.224-6.124 2.843-9.346.506-2.019 1.378-3.805 3.175-4.993 13.772 19.765 11.725 41.677 7.598 63.376-7.378 38.776-31.999 64.368-65.59 82.586-1.377.537-2.757 1.066-4.136 1.6-18.335 15.898-40.096 13.342-61.525 10.766-9.057-1.088-17.645-5.012-25.682-9.641-3.651-1.822-7.303-3.643-10.952-5.465-21.891-14.021-41.847-29.836-51.263-55.4-1.32-4.568 1.685-10.977-5.549-13.026-5.183-5.145-2.887-11.89-3.834-17.941z"
      />
      <path
        fill="#FD8F25"
        d="m25.348 86.543-3.415-3.993c6.875-10.683 14.303-20.893 24.643-28.59 6.877-6.412 14.009-12.292 24.287-11.553 2.149-.961 4.299-1.922 6.444-2.883 24.888-5.39 49.771-8.688 74.645.079.077 4.508-3.461 5.038-6.592 6.029-7.164 1.049-14.413.24-21.597.922-3.874.47-7.574 1.885-11.477 2.219-2.288.313-4.589.397-6.891.409-3.709.034-7.425-.036-11.134-.027-2.809.063-5.625.073-8.404.512-5.617 3.083-13.07 2.774-16.846 9.231-5.539 5.032-7.489 12.608-12.69 17.882-7.198-2.152-12.739 3.32-19.237 4.244-1.702.858-3.291 1.913-4.802 3.086-2.058 1.517-4.175 2.909-6.934 2.433z"
      />
      <path
        fill="#FD932D"
        d="M223.644 110.009a9144.18 9144.18 0 0 1-.562 7.375c-10.001-2.958-6.462-17.122-16.572-19.983-1.148-.879-1.99-1.998-2.557-3.322-.063-10.283-5.762-16.443-14.803-20.126-2.08-.512-4.099-1.176-6.026-2.122a12.176 12.176 0 0 1-4.572-4.547c-.921-2.049-1.185-4.192-.88-6.412.741-2.995 2.23-5.341 5.433-6.181 20.419 13.378 36.58 29.875 40.539 55.318z"
      />
      <path
        fill="#FD9128"
        d="m183.104 54.691-3.184 6.43c-11.165 3.983-19.911-7.745-30.924-4.726-4.978-.646-10.069-1.082-13.072-6.021-.132-1.509.46-2.683 1.666-3.574 2.013-1.379 4.612-.403 6.583-1.673-.014-.977.313-1.831.898-2.586l6.879-2.938c11.733 2.247 22.117 7.276 31.154 15.088z"
      />
      <path
        fill="#047FA3"
        d="M74.309 258.697c29.18 8.258 58.277 10.104 87.207-1.121 3.827 20.49 1.908 23.369-20.098 23.361-24.614-.014-47.988-5.039-67.109-22.24z"
      />
      <path
        fill="#FD9836"
        d="M6.545 184.805c2.66-1.024 2.85-5.954 6.647-4.741 2.965.945 1.962 4.138 2.265 6.49.548 4.256-1.614 7.703-3.363 11.273-3.368-3.691-4.973-8.138-5.549-13.022z"
      />
      <path
        fill="#6EB5CA"
        d="M8.953 103.534c.026 3.674-1.696 6.023-5.271 6.969-.504-4.465-1.052-8.939 2.11-12.802l3.161 5.833z"
      />
      <path
        fill="#F1F4F2"
        d="M70.862 42.408c-8.007 4.038-14.866 10.489-24.287 11.553 1.048-8.803 8.845-11.517 14.541-16.133 6.441-5.224 7.016 1.951 9.746 4.58z"
      />
      <path
        fill="#FD8919"
        d="m136.713 49.031 12.288 5.532c10.291.758 15.978 9.837 24.62 13.575 2.063 1.68 3.804 3.713 5.842 5.423 3.574 2.91 7.482 5.293 11.626 7.312 6.364 3.022 12.434 6.335 13.83 14.184l-.161-.218c7.565 9.143 13.899 18.929 16.121 30.864-3.898 1.885-.894 4.29-.491 6.5a18.854 18.854 0 0 1-3.339 2.543c-5.294 1.649-10.427 3.774-15.714 5.46-5.329.928-8.772-3.295-13.217-4.796-6.394-3.429-12.684-1.652-18.98.382-15.733-12.501-22.348-12.535-35.217-.148-10.578-.967-11.986-7.354-9.52-16.045 2.802-9.395 4.154-18.604-3.398-26.701-.919-8.646 11.704-13.739 6.156-23.615-.677-5.957-1.065-11.917-1.068-17.913.411-2.607 1.954-4.193 4.439-4.932 2.539-.274 4.741.259 6.183 2.593z"
      />
      <path
        fill="#FEFEFD"
        d="M125.964 119.265c.961 5.784 3.404 10.922 6.81 15.642 10.982 1.222 7.021 10.765 9.394 16.792.171 1.488.684 3.674-.184 4.354-6.672 5.229-3.037 5.526 2.348 5.174 10.989 6.754 10.514 18.268 12.065 28.953-.546 1.547-1.438 2.856-2.642 3.966-6.537 4.446-12.705 10.122-21.688 6.856-14.395.723-12.594-12.498-15.907-20.102-3.05-7.002-5.842-12.334-13.547-13.975-8.154-6.869-8.095-17.864-13.235-26.247-2.675-9.328-7.056-18.165-8.253-27.932.029-1.474.308-2.911.784-4.296a10.08 10.08 0 0 1 2.634-3.268c4.038-2.346 7.767-5.181 11.535-7.955 2.778-.467 6.016 1.655 8.354-1.498 4.091-1.449 8.154-1.361 12.214.042 6.614 6.432 10.765 13.854 9.318 23.494z"
      />
      <path
        fill="#FEFAF6"
        d="M93.127 138.378c3.61 9.367 8.182 18.413 9.485 28.547-11.667.14-23.755-2.528-34.292 5.145-2.866-1.94-3.55-5.217-4.991-8.048-1.361-3.083-2.717-6.166-2.627-9.652.25-1.41.73-2.729 1.406-3.99 8.909-7.691 19.135-11.99 31.019-12.002z"
      />
      <path
        fill="#FEFBF8"
        d="M132.067 201.001c6.885-3.191 13.768-6.383 20.652-9.576 4.185 3.65 5.419 9.197 8.703 13.439 2.591 3.689 3.486 7.721 2.481 12.141-.415 1.389-1.07 2.662-1.919 3.838-6.313 6.102-15.324 7.109-22.391 11.826-1.48.078-2.833-.291-4.046-1.158-3.888-6.779-9.297-12.992-8.138-21.672-1.562-4.588 2.1-6.422 4.658-8.838z"
      />
      <path
        fill="#FEF9F4"
        d="M50.216 156.058c-4.25 1.219-9.511 1.595-9.395-3.914.208-9.786-9.247-20.735 2.78-29.372.615-.488 1.232-.976 1.848-1.467 7.014-2.046 10.001 2.744 12.974 7.597 3.438 7.84 7.034 15.67 3.926 24.561l-.364.619c-3.468 3.37-7.468 3.567-11.769 1.976z"
      />
      <path
        fill="#FEF4EB"
        d="M201.271 197.117c-3.363 3.912-6.325 8.293-11.602 9.984-5.247 2.633-7.808-.178-8.607-4.834-1.199-6.988-2.638-14.061-.494-21.188.607-1.635 1.552-3.022 2.81-4.223 3.646-2.811 7.719-4.311 12.367-4.12 13.781 5.412 11.687 14.437 5.526 24.381z"
      />
      <path
        fill="#FEFBF9"
        d="M43.602 122.772c-18.121-2.486-7.174-15.985-9.299-24.534 1.327-3.441 3.69-5.89 7.059-7.394 1.311-.597 2.688-.934 4.12-1.088 3.918.079 6.84 1.828 8.767 5.241.771 2.001.885 4.065.549 6.163-1.628 5.175-1.345 10.419-.473 15.691a6.876 6.876 0 0 1-1.372 3.759c-3.033 1.504-6.937-.6-9.627 2.325l.276-.163z"
      />
      <path
        fill="#FEF6EF"
        d="M139.675 231.654c3.574-10.508 15.368-6.824 21.701-12.568 8.189 4.787 7.912 11.049 3.155 18.092-4.948 2.709-8.703 8.33-15.562 6.387-5.347-2.214-10.801-4.349-9.294-11.911z"
      />
      <path
        fill="#FEEEDE"
        d="M127.411 209.839c3.285 7.127 9.863 12.854 9.214 21.65-2.271 1.805-4.607 1.971-7.023.295-4.441-2.338-5.193-8.02-10.333-10.287-4.885-2.154-4.321-7.539-1.993-11.758 3.164-5.739 6.739-.507 10.135.1z"
      />
      <path
        fill="#FEF8F3"
        d="M50.216 156.058c3.461-3.411 7.763-1.819 11.769-1.977l3.21 8.949c.472 4.196 3.277 8.395-.018 12.587-13.697-1.006-17.765-6.324-14.961-19.559zM164.531 237.177c.06-6.225.535-12.52-3.155-18.092l.118-.152c2.281-5.701 8.631-1.813 11.976-5.084 4.373-.533 7.398 1.186 9.034 5.293 1.222 4.217.486 7.998-2.517 11.266-6.101.088-8.81 7.922-15.456 6.769z"
      />
      <path
        fill="#FD9735"
        d="M217.434 132.112c.985.024 1.97.055 2.954.091 1.86 4.565-.183 10.398 6.586 13.432 4.936 2.21 3.377 7.482.375 11.474-3.703-1.992-1.49-5.187-1.979-6.975-1.26 3.386.439 8.033-.951 12.375a16067.3 16067.3 0 0 0-7.117 10.016c-3.512 1.516-5.423-.485-7.015-3.183 2.704-6.884 5.553-13.784-.717-20.359a8.15 8.15 0 0 1 .384-3.929c4.174-3.329 3.575-9.441 7.48-12.942z"
      />
      <path
        fill="#FEECDA"
        d="M220.879 125.704c-5.315-10.319-13.172-19.311-16.121-30.864 16.532-.961 11.467 15.636 18.325 22.542-.168 2.925 1.546 6.348-2.204 8.322z"
      />
      <path
        fill="#FEEFE0"
        d="m129.602 231.785 7.023-.291c1.018.045 2.033.1 3.05.16 3.099 3.971 6.196 7.941 9.295 11.91-3.723.494-2.558 7.328-7.719 5.953 3.694-10.889-19.894-3.855-11.649-17.732z"
      />
      <path
        fill="#FEF4EA"
        d="M210.93 169.739c2.125.927 4.249 1.855 6.372 2.786l-9.526 21.102c-8.911-5.684-3.188-13.823-3.518-20.954 1.76-2.034 3.991-2.998 6.672-2.934z"
      />
      <path
        fill="#FDBD7F"
        d="M183.246 178.415c2.873 9.397-4.979 21.2 6.424 28.687a42.128 42.128 0 0 0-.572 2.383c-2.761 2.816-5.877 2.99-9.253 1.258-5.604-8.693-3.959-19.158-6.94-28.508 2.307-4.357 5.475-6.394 10.341-3.82z"
      />
      <path
        fill="#FD9836"
        d="M207.822 172.479c-3.78 7.042-6.063 14.087-.047 21.147-1.052 3.24-3.564 3.76-6.502 3.49-2.182-7.458 8.959-20.103-8.428-21.637.168-8.154 6.093-7.988 11.788-8.169 2.332.937 4.372 2.062 3.189 5.169z"
      />
      <path
        fill="#FEF5EC"
        d="M179.987 230.408c.047-3.875.096-7.748.145-11.623 1.688-3.844 2.112-9.834 8.807-5.193.944 7.697 2.589 15.767-8.952 16.816z"
      />
      <path
        fill="#FEC187"
        d="M77.482 231.369c1.809-3.584 4.732-3.686 7.508-1.73 2.303 1.621 2.601 4.268.831 6.699-3.283 4.498-7.246 5.146-11.788 1.85.563-2.573 2.952-4.218 3.449-6.819z"
      />
      <path
        fill="#FECA98"
        d="M10.729 155.451c2.181 5.945 3.597 11.01-3.104 15.712-1.398-6.057-3.833-11.295 3.104-15.712z"
      />
      <path
        fill="#FD9E42"
        d="M188.938 213.591c-4.822-1.467-5.127 4.727-8.807 5.193-2.322-4.598-6.272-2.355-9.699-2.307-.431-7.07 3.956-7.652 9.318-6.918 3.116-.023 6.232-.049 9.348-.076l-.16 4.108z"
      />
      <path
        fill="#FEEFE1"
        d="M93.926 235.664c5.732-8.676 10.089-6.08 13.817.852-4.906 4.989-9.056-1.837-13.817-.852z"
      />
      <path
        fill="#FEE3C9"
        d="m25.348 86.543 5.558-4.347c4.063 4.447 1.104 8.409-.85 12.441-.439 1.461-1.237 2.701-2.336 3.75-10.606-1.983-1.445-7.925-2.372-11.844z"
      />
      <path
        fill="#FEFEFE"
        d="M15.73 120.053c.272 4.004-.892 7.109-5.216 8.285-3.181-5.502 1.337-9.683 2.457-14.436 5.733-.106 4.19 3.046 2.759 6.151z"
      />
      <path
        fill="#FEEFE1"
        d="m27.72 98.387.509-3.55 2.031-.937 1.915 1.155c-.713 7.057-2.931 13.078-10.59 15.342l.205.209c-1.655-5.835 3.888-8.178 5.93-12.219z"
      />
      <path
        fill="#FDA24B"
        d="M43.448 91.748c-3.048 2.164-6.098 4.329-9.146 6.49l-2.128-3.183-.844.025-.846-.012c-.106-4.499 1.187-8.246 5.573-10.325 3.805.918 5.944 3.597 7.391 7.005z"
      />
      <path
        fill="#FEE5CD"
        d="M77.482 231.369c5.686 5.729.173 5.793-3.449 6.818-1.366-1.316-4.014-2.793-3.871-3.914.596-4.666 4.896-1.713 7.32-2.904z"
      />
      <path
        fill="#FEE2C6"
        d="M15.73 120.053c-.921-2.049-1.841-4.099-2.759-6.151 1.452-5.081 5.673-2.752 8.82-3.295-.002 0-.203-.212-.205-.212.569 4.75-2.607 7.227-5.856 9.658z"
      />
      <path
        fill="#FEF2E7"
        d="M224.419 162.509c-1.772-4.596-3.373-9.186.438-13.675 3.491 2.122 1.686 5.575 2.492 8.276l-2.93 5.399z"
      />
      <path
        fill="#FEC691"
        d="M65.177 175.617c1.642-4.192-3.396-8.394.018-12.587 3.316 2.229 3.634 5.49 3.126 9.04-.116 2.007-1.029 3.31-3.144 3.547z"
      />
      <path
        fill="#FEEEDF"
        d="M55.587 75.263c1.253-8.067 4.298-14.851 12.718-17.792 3.091-.121 5.6.992 7.452 3.504.943 2.016 1.104 4.126.775 6.3-1.403 6.193-2.737 12.438-8.168 16.667-3.177 3.095-7.635 2.07-11.354 3.52-2.281.061-4.212-.591-5.488-2.625-1.494-4.409 1.222-7.019 4.065-9.574z"
      />
      <path
        fill="#FEF1E5"
        d="M106.324 45.605c1.505.061 3.011.125 4.516.188 1.249.297 2.232.985 2.998 2.01 1.31 2.176 2.337 4.496 3.568 6.724 1.746 3.229 1.941 6.366-.552 9.343a10.665 10.665 0 0 1-3.396 2.274c-4.67 1.285-9.519 1.519-14.272 2.446a10.342 10.342 0 0 1-4.238-.84c-3.942-2.528-6.664-5.884-7.27-10.677-.037-2.265.582-4.311 2.08-6.048 4.717-4.246 12.277.174 16.566-5.42z"
      />
      <path
        fill="#FEEFE1"
        d="M55.587 75.263c-.941 3.274-1.885 6.545-2.826 9.819-.432 6.027-6.734 3.999-9.453 6.888l.143-.221c-4.049.076-6.562-1.456-6.243-5.957-2.083-1.622-3.013-3.798-3.063-6.394 4.077-5.002 11.259-4.205 15.935-9.71 3.715-4.375 1.308 5.769 5.507 5.575z"
      />
      <path
        fill="#FD922A"
        d="M89.971 51.393c-.136 1.746-.272 3.489-.41 5.232-3.854 6.46-9.619 4.705-15.244 3.547-2.633.5-5.26.988-6.012-2.701.04-10.968 12.647-5.341 15.909-12.053 1.323 1.885 3.557 2.737 5.053 4.429.24.512.472 1.03.704 1.546z"
      />
      <path
        fill="#FD9939"
        d="M106.324 45.605c-.549 15.782-12.179.252-16.354 5.787l.088.203c-2.285-3.11.499-4.32 2.224-5.926l14.042-.064z"
      />
      <path
        fill="#FEE6D0"
        d="m136.713 49.031-5.656-1.358c-3.066-1.953-10.046 3.041-9.485-5.348 7.835.073 15.671.143 23.503.215.503.773 1.008 1.546 1.51 2.322-2.575 3.541-7.13.582-10.03 3.056l.158 1.113z"
      />
      <path
        fill="#FDA148"
        d="M121.571 42.326c1.738 4.305 7.277 1.876 9.483 5.351l-3.169 3.38c-.532.543-1.063 1.083-1.593 1.628-5.836 1.916-10.267-.943-14.553-4.287a34.953 34.953 0 0 1-.899-2.604c3.577-1.155 7.154-2.31 10.731-3.468z"
      />
      <path
        fill="#FEF4EA"
        d="M34.144 79.397c2.563 1.392 2.993 3.805 3.064 6.394l-6.723 9.276a61.982 61.982 0 0 0-2.26-.23c.896-4.214 1.789-8.428 2.68-12.641 1.08-.934 2.159-1.868 3.239-2.799z"
      />
      <path
        fill="#FEEDDD"
        d="M92.282 45.669c-.741 1.977-1.482 3.953-2.224 5.926-3.452-.636-7.401-.803-5.845-6.178 2.69.085 5.381.167 8.069.252z"
      />
      <path
        fill="#FEEEDE"
        d="M173.985 69.739c-11.566.273-16.517-10.343-24.984-15.176 12.105-6.29 20.678 4.068 30.92 6.557l.513 5.775c-1.518 2.38-3.74 3.166-6.449 2.844z"
      />
      <path
        fill="#FEEAD7"
        d="M204.919 95.058c-3.382-6.424-11.326-7.002-15.653-12.214-2.913-4.035-3.606-8.073-.006-12.111 14.268 5.227 20.65 15.14 15.659 24.325z"
      />
      <path
        fill="#FEF7F1"
        d="m189.26 70.733.006 12.111c-5.562.491-8.276-4.253-12.258-6.666-1.552-5.66 2.897-5.348 6.172-6.203 2.026.253 4.052.504 6.08.758z"
      />
      <path
        fill="#FEE4CA"
        d="m183.18 69.976-6.172 6.203c-4.222-.637-2.774-3.938-3.022-6.439 1.411-2.622 3.939-2.707 6.446-2.844.917 1.024 1.833 2.052 2.748 3.08z"
      />
      <path
        fill="#FEF5EC"
        d="M136.554 47.919c2.61-3.423 6.6-2.319 10.028-3.056 1.509.579 4.9 1.58 1.721 2.926-3.336 1.412-7.786.196-11.749.13z"
      />
      <path
        fill="#FEFCFA"
        d="M138.796 150.595c-1.754-5.326-.174-11.935-6.022-15.688-.748-8.903 4.375-10.913 12.147-11.911 11.565-1.485 21.438-1.146 25.671 12.117.55.518 1.099 1.04 1.656 1.555 1.904 3.613 1.753 7.233.049 10.862-6.193 8.206-15.467 10.04-24.741 11.901-6.169.274-5.295-6.426-8.76-8.836z"
      />
      <path
        fill="#FEF9F5"
        d="M130.438 69.973c8.305 12.093-2.677 16.003-9.243 21.787-.507 2.141-1.782 3.562-3.911 4.181-3.645-.306-6.064-2.347-7.737-5.448-1.395-2.877-3.423-5.263-5.855-7.342-2.817-2.986-5.032-6.293-5.727-10.44.052-1.431.585-2.655 1.581-3.677 4.374-4.011 10.658-2.322 15.423-5.169l-.2.121c8.128-5.609 11.857.297 15.669 5.987z"
      />
      <path
        fill="#FEF1E4"
        d="m117.975 94.87 3.221-3.11c11.923 4.259 14.019 16.334 4.772 27.508-2.658-7.021-5.313-14.039-7.968-21.057-1.771-1.101-1.778-2.213-.025-3.341z"
      />
      <path
        fill="#FEF2E7"
        d="M170.592 135.113c4.318-13.451 12.571-2.504 18.97-3.062 5.021 3.78 4.171 7.536-.027 11.301-3.982 2.634-8.186 4.408-13.111 3.259-6.031-1.722-2.413-8.331-5.772-11.414l-.06-.084z"
      />
      <path
        fill="#FEE3C9"
        d="M217.434 132.112c-1.514 4.732 6.531 14.06-6.396 13.308-4.459 2.016-5.637 7.824-10.658 9.21-5.346-2.541-4.917-6.966-3.829-11.623.951-2.334 3.362-3.131 4.99-4.753 4.397-4.375 9.219-7.658 15.893-6.142z"
      />
      <path
        fill="#FDA045"
        d="M130.438 69.973c-4.524-3.826-9.463-6.566-15.67-5.987.4-2.88 4.349-5.281 1.173-8.648 2.487-4.881 8.422.764 11.071-3.626 0 0 .865-.651.868-.651 4.662 5.789 2.137 12.549 2.558 18.912z"
      />
      <path
        fill="#FDA34C"
        d="M201.54 138.254c-.002 2.088-.933 3.419-3.064 3.777-3.093 2.049-6.003 2.046-8.664-.764 1.769-3.123 2.704-6.221-.25-9.216l11.978 6.203z"
      />
      <path
        fill="#FEFDFB"
        d="M62.349 153.462c-2.173-8.173-4.343-16.346-6.514-24.521-1.995-2.268-1.548-5.011-1.501-7.672.361-1.301 1.036-2.428 1.96-3.408 3.739-2.559 7.667-4.869 11.033-7.97 1.161-.661 2.405-1.067 3.72-1.276 4.412-.012 9.269-2.498 12.867 2.213l9.213 27.55c-9.358 6.87-21.954 7.13-30.778 15.084z"
      />
      <path
        fill="#FEFBF8"
        d="M148.971 157.013c7.137-3.104 14.271-6.208 21.407-9.316 2.998 0 5.782.549 7.819 3.031 1.171 1.828 1.781 3.853 2.067 5.981.502 4.644-.028 9.437 1.979 13.878.958 2.51 2.278 4.98.984 7.77l.018.058c-3.942-.952-6.433 2.18-9.598 3.417-1.653 8.157-7.827 10.079-14.883 10.807-1.389-.109-2.575-.664-3.562-1.646-6.594-8.32-2.157-21.759-13.031-28.095-.753-5.455 5.083-3.291 6.8-5.885z"
      />
      <path
        fill="#FEFBF9"
        d="M99.565 73.262c1.985 2.974 3.973 5.945 5.959 8.916 1.215 3.684 3.433 7.206 1.829 11.35-.985 2.595-4.121 3.377-5.057 6.015-2.37-.288-4.743-.579-7.113-.87-.811 3.971-6.348 3.653-7.022 7.745-1.079.306-2.158.616-3.237.925-3.385-.042-5.378-2.152-7.08-4.72-3.689-7.43-6.866-14.927-2.321-23.194 1.144-1.9 2.644-3.48 4.387-4.832 4.854-2.659 8.881-7.539 15.39-5.881 2.051.92 2.809 3.063 4.265 4.546z"
      />
      <path
        fill="#FDB26A"
        d="M102.297 99.542c1.313-2.683 2.624-5.369 3.937-8.055 1.029-2.219 2.441-3.741 5.183-2.834 1.703 2.583 2.694 5.914 6.557 6.217.009 1.113.019 2.225.027 3.341-5.234.442-10.471.888-15.704 1.331z"
      />
      <path
        fill="#FDC28A"
        d="M148.971 157.013c-2.263 1.961-4.524 3.926-6.792 5.884-12.888-.882 2.799-9.583-3.377-12.302 4.385.554 4.802 7.409 10.169 6.418z"
      />
      <path
        fill="#FD942F"
        d="m80.652 101.385 4.271 5.957-1.01 3.486-12.39-.391c-.303-6.394 2.731-9.419 9.129-9.052z"
      />
      <path
        fill="#FDB36B"
        d="M155.204 190.992c1.002.201 2.013.301 3.034.304 4.494 5.075 4.87 10.489 1.332 16.224-6.183-3.703-5.362-10.395-6.855-16.094a3.454 3.454 0 0 0 2.489-.434z"
      />
      <path
        fill="#FDA44E"
        d="M88.161 106.417c-1.755-6.299 5.15-4.741 7.024-7.745 1.146 5.747-4.899 4.968-7.024 7.745z"
      />
      <path
        fill="#FEFCFA"
        d="m159.57 207.519-1.332-16.224c5.102-3.214 11.555-4.229 15.412-9.464 10.43 7.394 5.85 18.094 6.101 27.729-2.528 3.084-7.745 2.543-9.317 6.918a7980.45 7980.45 0 0 1-8.941 2.459c-.523-3.826 1.137-8.02-1.923-11.418z"
      />
      <path
        fill="#FDA34C"
        d="m55.975 119.662-.14 9.279c-4.066-2.216-6.454-7.927-12.51-6.005 1.569-4.766 6.127-3.495 9.465-4.684 1.328-.13 2.389.34 3.185 1.41z"
      />
      <path
        fill="#FEFAF5"
        d="M183.229 178.357c-1.064-1.791-2.128-3.58-3.194-5.369-2.153-5.017-.144-10.832-3.002-15.712l-.181-6.745c-1.663-2.334-1.894-4.432.999-6.008l11.959-3.256 8.666.764c.353 4.135-.848 8.628 2.768 12.011 3.583 1.861 4.352 5.423 5.368 8.846.304 2.216.077 4.277-1.813 5.811-4.307 1.692-9.249 2.265-11.953 6.781-3.206.958-6.411 1.916-9.617 2.877z"
      />
      <path
        fill="#FEF8F3"
        d="M55.975 119.662c-1.063-.47-2.124-.937-3.185-1.407 0-.003.083-.024.083-.024-2.194-5.533-2.504-11.095-.231-16.691l-.013-7.054c-4.22-7.467 3.702-6.227 6.309-8.797 2.107-1.119 4.216-2.234 6.322-3.353 4.101-.946 7.682-4.496 12.375-2.456-2.761 7.697-.088 14.633 3.018 21.505l-9.128 9.052-3.426.082c-2.447 5.305-9.213 4.929-12.321 9.364l.197-.221z"
      />
      <path
        fill="#FD9632"
        d="M58.938 85.688c-1.653 3.252-6.296 4.365-6.309 8.797-3.16-.643-5.563-4.093-9.321-2.516 3.151-2.298 6.303-4.593 9.453-6.888 2.058.201 4.118.404 6.177.607z"
      />
      <path
        fill="#FDAD5F"
        d="m52.642 101.54.233 16.688c-4.526-5.497-5.24-11.053-.233-16.688z"
      />
      <path
        fill="#FEF0E2"
        d="m204.799 168.699-.061-5.96c.464-5.608-8.076-15.033 6.259-14.757 13.699 7.291 8.63 14.53-.065 21.757-1.037.912-2.073 1.828-3.109 2.74.33-2.328-1.169-3.198-3.024-3.78z"
      />
      <path
        fill="#FD922B"
        d="M210.997 147.982c-11.655.861-3.905 9.952-6.259 14.757-4.746-1.461-2.19-5.854-3.495-8.697-.261-6.881 2.351-10.495 9.794-8.622a121.6 121.6 0 0 0-.04 2.562z"
      />
      <path
        fill="#FEF1E5"
        d="M95.831 70.127c-5.139 2.083-10.275 4.162-15.412 6.245-8.499 2.362-7.945-2.504-6.473-8.106l.37-8.091c3.954-6.029 10.154-2.404 15.245-3.547l6.841 10.795c.771.791.944 1.455.519 1.995-.375.473-.738.709-1.09.709z"
      />
      <path
        fill="#FDA046"
        d="M73.946 68.266c-.16 4.553.299 8.612 6.473 8.106-.93 1.167-1.857 2.337-2.784 3.507-3.743 2.753-7.933 3.244-12.375 2.456 6.117-2.701 5.229-9.725 8.686-14.069z"
      />
      <path
        fill="#FEF6EE"
        d="M127.013 51.711c-.293 11.596-7.568 1.846-11.071 3.626-2.94-1.379-4.008-3.896-4.201-6.939 5.916-2.705 9.825 3.844 15.272 3.313z"
      />
      <path
        fill="#FDB56E"
        d="M114.969 63.864c-3.859 5.363-10.553 3.756-15.549 6.321-.983-.576-1.323-1.173-1.02-1.792.308-.625.618-.937.939-.937 4.16-5.768 10.216-3.286 15.63-3.592z"
      />
      <path
        fill="#FD8818"
        d="M99.34 67.457a6.048 6.048 0 0 0 .08 2.729l.145 3.077c-1.243-1.042-2.487-2.088-3.733-3.134.33-.873.521-1.773.569-2.704a4.797 4.797 0 0 1 2.939.032z"
      />
      <path
        fill="#FEC289"
        d="m177.851 144.523-.999 6.008c-2.157-.946-4.313-1.889-6.473-2.834l.271-12.499c4.467 1.512 1.634 8.661 7.201 9.325z"
      />
      <path
        fill="#FD9C40"
        d="M111.416 88.653c-1.729.946-3.456 1.892-5.183 2.834-.203-3.104-2.76-6.033-.709-9.31 3.624.649 6.971 1.55 5.892 6.476z"
      />
      <path
        fill="#FDB36C"
        d="M55.777 119.883c.593-7.745 7.928-6.621 12.321-9.364-.285 8.148-7.646 6.99-12.321 9.364z"
      />
      <path
        fill="#FDAF63"
        d="M177.032 157.276c4.555 4.556 4.826 9.934 3.002 15.712-4.351-4.595-3.081-10.267-3.002-15.712z"
      />
    </g>
  </svg>;

export default LogoEjatlas;