import React, {FC, useEffect, useState} from 'react';
import {Autocomplete, TextField} from '@mui/material';
import {BASE_URL_API} from '../../config';
import useFetch from '@geomatico/geocomponents/hooks/useFetch';
import {Field} from './AdvancedSearch';

const autocompleteStyles = {
  '& .MuiOutlinedInput-root': {
    padding: '1px 9px'
  },
  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: '2.5px 4px 2px 6px'
  }
};
export type AutocompleteWithSearchProps = {
  field: Field,
  onFilterValuesChange: (_entity: string, _index: number, _selection: SearchOption )=> void,
  index: number,
  value?: string,
};

type SearchOption = { id: string, slug: string, label: string };

export const AutocompleteWithSearch: FC<AutocompleteWithSearchProps> = ({onFilterValuesChange, value, index, field}) => {

  const [searchOptions, setSearchOptions] = useState<Array<SearchOption>>([]);
  const [searchUrl, setSearchUrl] = useState<string | null>(null);
  const {data: searchResults} = useFetch(searchUrl && `${BASE_URL_API}/${searchUrl}`);


  useEffect(() => {
    if (searchResults) {
      const n = searchResults.map((el: { name: string, slug: string }) => ({id: el.slug, slug: el.slug, label: el.name}));
      setSearchOptions(n);
    }
  }, [searchResults]);


  const handleSearchAutocomplete = (x: any) => {
    if (x.target.value.length >= 3) setSearchUrl(field?.url + x.target.value);
  };

  const handleAutocompleteOnChange =((event: React.SyntheticEvent, value: SearchOption)=> {
    onFilterValuesChange('value', index, value);
  });


  return <Autocomplete
    disablePortal
    disableClearable={true}
    value={value ? value?.charAt(0).toUpperCase() + value?.slice(1): ''}
    options={searchOptions}
    sx={autocompleteStyles}
    //@ts-ignore error de tipos de MUI
    onChange={handleAutocompleteOnChange}
    onKeyUp={handleSearchAutocomplete}
    freeSolo
    renderInput={(params) => {
      return <TextField {...params} placeholder='Select field'/>;
    }}
  />;
};
