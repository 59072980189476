import React from 'react';
import Box from '@mui/material/Box';
import {useTranslation} from 'react-i18next';

import useFetch from '@geomatico/geocomponents/hooks/useFetch';
import {API_GET_FEATURED_MAP_LIST, ENTITIES} from '../../config';
import {ListCard, ListCardProps} from '../conflict-list/ListCard';
import {Container} from '@mui/material';
import Typography from '@mui/material/Typography';

export const FeaturedMapList = () => {

  const {t} = useTranslation();

  const {data: featuredMapList} = useFetch(API_GET_FEATURED_MAP_LIST(100, 0));
  return <Box sx={{overflow: 'scroll', height: '100vh'}}>
    <Container maxWidth="lg" sx={{mt: 14}}>
      <Typography variant="h4" gutterBottom mt={5} mb={0} mx={2} p={2}>
        {t('newlyPublishedFeaturedMaps')}
      </Typography>
      {
        featuredMapList?.results.length ?
          <Box mx={2} display='flex' width={'100%'} flexWrap='wrap'>
            {featuredMapList.results.map((result: ListCardProps, i: number) => (
              <Box key={i} p={2} width={'33%'}>
                <ListCard name={result.name} headline={result.headline} image={result.image} slug={result.slug} entity={ENTITIES.FEATURED}/>
              </Box>
            ))
            }
          </Box>
          : <Box mt={2} p={2} fontStyle='italic' textAlign='center'>{t('noResults')}</Box>
      }
    </Container>
  </Box>;
};
