import {useState} from 'react';

export type LatLonZoomType = {
  latitude: number,
  longitude: number,
  zoom: number
}

export const position2viewport = (position: string): LatLonZoomType => {
  const [latitude, longitude, zoom] = position.split(',').map(Number);
  return {latitude, longitude, zoom};
};

const viewport2position = (viewport: LatLonZoomType) => {
  const {latitude, longitude, zoom} = viewport;
  // Round decimals, see hash implementation in maplibre:
  // https://github.com/maplibre/maplibre-gl-js/blob/main/src/ui/hash.ts#L57
  const z = Math.round(zoom * 100) / 100;
  const precision = Math.ceil((z * Math.LN2 + Math.log(512 / 360 / 0.5)) / Math.LN10);
  const m = Math.pow(10, precision);
  const lat = Math.round(latitude * m) / m;
  const lon = Math.round(longitude * m) / m;
  return `${lat},${lon},${z}`;
};

const usePosition = (initialPosition?: string) => {

  const [position, setPosition] = useState<string | undefined>(initialPosition);
  const [viewport, setViewport] = useState<LatLonZoomType | undefined>(initialPosition ? position2viewport(initialPosition) : undefined);

  const handleSetPosition = (position: string) => {
    setPosition(position);
    setViewport(position2viewport(position));
  };

  const handleSetViewport = (newViewport: LatLonZoomType) => {
    setViewport({
      ...viewport,
      ...newViewport
    });
    setPosition(viewport2position(newViewport));
  };

  return {
    position,
    viewport,
    setPosition: handleSetPosition,
    setViewport: handleSetViewport
  };
};

export default usePosition;
