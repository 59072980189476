import React, {FC} from 'react';
import GeoJSON from 'geojson';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import {SxProps} from '@mui/system';
import styled from '@mui/styles/styled';
import {Popup} from 'react-map-gl';
import useMediaQuery from '@mui/material/useMediaQuery';
import {POP_UP_WIDTH_MOBILE, POP_UP_WIDTH_WIDESCREEN, SM_BREAKPOINT} from '../config';
import {LegendIcon} from './LegendSelector';
import Typography from '@mui/material/Typography';
import {useNavigate} from 'react-router-dom';
import List from '@mui/material/List';
import { ListItemAvatar} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

const closeIconStyles: SxProps = {
  position: 'absolute',
  zIndex: 3000,
  right: '-8px',
  top: '-8px',
  background: 'white',
  display: 'flex',
  borderRadius: '23px',
  justifyContent: 'center',
  cursor: 'pointer'
};

const PopupInfo = styled(Popup)({
  cursor: 'default',
  position: 'relative',
  '& .mapboxgl-popup-content': {
    padding: 0
  }
});
export type PopUpProps = {
  features: Array<GeoJSON.Feature<GeoJSON.Point>> | undefined,
  onFeatureChange: (_feature: GeoJSON.Feature<GeoJSON.Point> | undefined) => void,
  onClusteredFeaturesChange: (_feature: Array<GeoJSON.Feature<GeoJSON.Point>> | undefined) => void,
};

export const ClusterPopUp: FC<PopUpProps> = ({features, onFeatureChange, onClusteredFeaturesChange}) => {

  const navigate = useNavigate();
  const widescreen = useMediaQuery(`@media (min-width:${SM_BREAKPOINT}px)`, {noSsr: true});

  const handleOnFeatureClick = (feature: GeoJSON.Feature<GeoJSON.Point>,slug: string) => {
    onClusteredFeaturesChange(undefined);
    onFeatureChange(feature);
    navigate(`../conflict/${slug}`);
  };

  const handleOnClose =() => {
    onClusteredFeaturesChange(undefined);
  };

  return <>
    {features &&
      <PopupInfo
        style={{maxWidth: widescreen ? POP_UP_WIDTH_WIDESCREEN : POP_UP_WIDTH_MOBILE}}
        longitude={features[0].geometry?.coordinates[0]}
        latitude={features[0].geometry?.coordinates[1]}
        closeButton={false}
        onClose={handleOnClose}
      >

        <Box onClick={handleOnClose} sx={closeIconStyles} position='absolute'><CloseIcon
          sx={{fontSize: '1rem'}}/></Box>
        <List component="nav" sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
          {features?.map((feature: GeoJSON.Feature<GeoJSON.Point>) => {
            return <ListItemButton key={feature?.properties?.slug} onClick={() => handleOnFeatureClick(feature, feature?.properties?.slug)} alignItems="flex-start">
              <ListItemAvatar>
                <LegendIcon slug={feature?.properties?.legend_slug} color={feature?.properties?.color}/>
              </ListItemAvatar>
              <ListItemText
                primary={''}
                secondary={
                  <Typography
                    sx={{display: 'inline'}}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {feature?.properties?.name}
                  </Typography>
                }
              />
            </ListItemButton>;
          })}
        </List>
      </PopupInfo>
    }
  </>;
};
