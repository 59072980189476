import React, {FC, useMemo} from 'react';
import Box from '@mui/material/Box';
import FooterLogos from './FooterLogos';
import {SxProps} from '@mui/system';

export type MapOverlayProps = {
  isRightDrawerOpen: boolean,
};

const MapOverlay: FC<MapOverlayProps> = ({isRightDrawerOpen}) => {
  console.log('isRightDrawerOpen', isRightDrawerOpen);

  const footerLogosContainer: SxProps = useMemo(() => ({
    position: 'absolute',
    left: 120,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    columnGap: {
      xs: 1.5,
      sm: 1,
      md: 1.5,
      lg: 1.5,
      xl: 1.5,
    },
    width: {
      xs: '80vw',
      sm: isRightDrawerOpen? '20vw' : '90vw',
      md: isRightDrawerOpen ? '25vw' : '70vw',
      lg: isRightDrawerOpen ? '45vw' : '70vw',
      xl: isRightDrawerOpen ? '65vw' : '90vw'
    }
  }), [isRightDrawerOpen]);

  return <Box sx={footerLogosContainer}>
    <FooterLogos/>
  </Box>;
};

export default MapOverlay;
