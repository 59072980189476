import React, {FC} from 'react';
import styled from '@mui/system/styled';
import Badge from '@mui/material/Badge';
import {Avatar} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import LoginIcon from '@mui/icons-material/Login';
import {useUserSession} from '../hooks/useUserSession';

const StyledBadge = styled(Badge)(({theme}) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

export const AvatarComponent: FC = () => {
  const {user} = useUserSession();

  return <Tooltip title={user ? `${user.name} ${user.surname}` : ''} disableHoverListener={!user} sx={{mr: 1}}>
    {user ?
      <a href={`/backoffice/user/update/${user.id}/`} rel='noreferrer' target='_blank'>
        <StyledBadge
          invisible={!user}
          overlap="circular"
          anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
          variant="dot"
        >
          <Avatar src="/broken-image.jpg" sx={{width: 32, height: 32, cursor: 'pointer'}}/>
        </StyledBadge>
      </a>
      : <a href={'/backoffice/login/'} rel='noreferrer' target='_blank'>
        <Button endIcon={<LoginIcon/>} sx={{
          mr: 2,
          color: 'white',
          border: '1px solid white',
          padding: '4px 9px',
          lineHeight: 0,
          '& .MuiButton-endIcon': {marginLeft: '2px'}
        }} variant="outlined">LOGIN</Button>
      </a>
    }
  </Tooltip>;
};
