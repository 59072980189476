import React from 'react';
import useFetch from '@geomatico/geocomponents/hooks/useFetch';
import {API_MANIFEST} from '../../config';
import {FeaturedMapList} from '../../components/featured-map/FeaturedMapList';
import Layout from '../Map/Layout';
import LeftSidePanelContent from '../Map/LeftSidePanelContent';

const IndexFeaturedMapView = () => {

  const {data: manifest} = useFetch(API_MANIFEST);

  const leftSidePanelContent = <LeftSidePanelContent navBarConfig={manifest?.navBarConfig}/>;
  const mainContent = <FeaturedMapList/>;

  return <>
    <Layout
      leftSidePanelContent={leftSidePanelContent}
      mainContent={mainContent}
      rightSidePanelContent={null}
      mapStyle={null}
      navBarConfig={manifest?.navBarConfig}
      onDrawerWidthChange={()=> null}
    />
  </>;
};

export default IndexFeaturedMapView;