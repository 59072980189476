import React, {FC} from 'react';
// MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import {useTranslation} from 'react-i18next';
import {LegendIcon} from './LegendSelector';
import {useTheme} from '@mui/styles';
import {Theme} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {sanitizeHTML} from '../sanitazeHTML';
import {Feature} from 'geojson';

type PopUpContentProps = {
  selectedFeature: Feature | undefined,
}

const PopUpContent: FC<PopUpContentProps> = ({selectedFeature}) => {
  const {t} = useTranslation();
  const theme = useTheme<Theme>();
  const navigate = useNavigate();

  const handleOpenConflictDetail = (slug: string) => {
    navigate(`../conflict/${slug}`);
  };

  const headline =  selectedFeature?.properties?.headline ? `<p>${selectedFeature?.properties?.headline}</p>` : null;

  return <>
    <Box sx={{p: 1, background: theme.palette.primary.main}}>
      <Typography variant="subtitle2" color='white' sx={{fontWeight: 'bold'}}>
        {selectedFeature?.properties?.name}
      </Typography>
    </Box>
    <Box sx={{p: 1}}>
      {headline &&
        <Typography variant="caption">{sanitizeHTML({html: headline})}</Typography>
      }

      <Link onClick={() => handleOpenConflictDetail(selectedFeature?.properties?.slug)} sx={{cursor: 'pointer'}}>
        <Typography variant="caption">
          {t('seeMore')}
        </Typography>
      </Link>
    </Box>
    <Box mt={2} p={1} display='flex' alignItems='center'>
      <LegendIcon slug={selectedFeature?.properties?.legend_slug} color={selectedFeature?.properties?.color}/>
      <Typography variant="caption">
        {t(selectedFeature?.properties?.legend_slug)}
      </Typography>
    </Box>
  </>;
};

export default PopUpContent;
