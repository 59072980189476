import React, {FC, useMemo} from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import {lighten} from '@mui/material/styles';
import {useTheme} from '@mui/styles';
import {Theme} from '@mui/material';

export type ConflictLanguageProps = {
  availableLangs: Array<string>,
  activeLang: string,
  onLanguageChange: (_lang: string) => void,
  style: 'primary' | 'secondary',
  motherTongue: string,
};

const ConflictLanguage: FC<ConflictLanguageProps> = ({availableLangs, activeLang, onLanguageChange, style, motherTongue}) => {
  const theme: Theme = useTheme();

  const selectedButton = useMemo(() => ({
    backgroundColor: lighten(theme.palette[style].main, 0.55),
    color: theme.palette[style].main,
    borderRadius: 0,
    '&:hover': {
      color: 'white',
      backgroundColor: lighten(theme.palette[style].main, 0.55),
    }
  }), [style]);

  const unselectedButton = useMemo(() => ({
    borderRadius: 0,
    '&:hover': {
      color: 'white',
      backgroundColor: lighten(theme.palette[style].main, 0.55),
    }
  }), [style]);

  return (
    <ButtonGroup color={style} variant="text" sx={{height: 28, display: 'flex', justifyContent: 'flex-start'}}>
      {
        availableLangs.map(lang =>
          <Button
            key={lang}
            onClick={() => onLanguageChange(lang)}
            sx={lang === activeLang ? selectedButton : unselectedButton}
          >
            {motherTongue === lang ? <b>{lang.toUpperCase()}</b> : lang.toUpperCase()}
          </Button>)
      }
    </ButtonGroup>
  );
};

export default ConflictLanguage;
