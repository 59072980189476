import React, {FC} from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {useTranslation} from 'react-i18next';
import {Conflict} from '../../../commonTypes';
import Divider from '@mui/material/Divider';
import ReactPlayer from 'react-player';

const generatorSectionConflictSources = (t: any, title: string | null, item: Array<any>, divider = true) => {

  return <>
    {title && <Typography variant="h6" gutterBottom>
      {title}
    </Typography>}

    {item?.map((item) => {
      return <>
        <Typography key={item.title} variant="body2" component="div" gutterBottom mt={2}>
          <Typography component="span" variant="body2" gutterBottom sx={{mr: 1}}>
            {item.title || item.description || item.name}
          </Typography>
          <br/>
          {!item.is_video &&
            <Typography variant="caption" gutterBottom sx={{mr: 1}}>
              <Link href={item.url || item.document} target="_blank" rel="noreferrer" sx={{cursor: 'pointer'}}>
                {t('clickToView')}
              </Link>
            </Typography>
          }

          {item.is_video && <ReactPlayer url={item.url}/>}
        </Typography>
      </>;
    })
    }
    {divider && <Divider sx={{mb: 3}}/>}
  </>;
};

export type ConflictSourcesAndMaterialsProps = {
  sources_and_materials: Conflict['sources_and_materials'],
}

export const ConflictSourcesAndMaterials: FC<ConflictSourcesAndMaterialsProps> = ({sources_and_materials}) => {
  const {t} = useTranslation();

  return <>
    {generatorSectionConflictSources(t, t('conflictReferences'), sources_and_materials?.cited_sources)}
    {generatorSectionConflictSources(t, t('conflictRelatedMedialinks'), sources_and_materials?.additional_sources)}
    {generatorSectionConflictSources(t, t('conflictDocuments'), sources_and_materials?.documents)}
  </>;
};
