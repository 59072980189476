import { useCallback, useEffect, useState } from 'react';
import {MAX_DEFAULT_DRAWER_WIDTH} from '../config';

type UseResizeProps = {
  minWidth: number
}

type UseResizeReturn = {
  width: number
  enableResize: () => void
}

const useResize = ({
  minWidth,
}: UseResizeProps): UseResizeReturn => {
  const maxWitdh = MAX_DEFAULT_DRAWER_WIDTH;
  const [isResizing, setIsResizing] = useState(false);
  const [width, setWidth] = useState(minWidth);

  const enableResize = useCallback(() => {
    setIsResizing(true);
  }, [setIsResizing]);

  const disableResize = useCallback(() => {
    setIsResizing(false);
  }, [setIsResizing]);

  const resize = useCallback(
    (e: MouseEvent) => {
      if (isResizing) {
        const newWidth = e.view ? e.view.innerWidth - e.clientX : e.clientX; // You may want to add some offset here from props
        if (newWidth >= minWidth && newWidth <= maxWitdh) {
          setWidth(newWidth);
        }
      }
    },
    [minWidth, isResizing, setWidth],
  );

  useEffect(() => {
    document.addEventListener('mousemove', resize);
    document.addEventListener('mouseup', disableResize);

    return () => {
      document.removeEventListener('mousemove', resize);
      document.removeEventListener('mouseup', disableResize);
    };
  }, [disableResize, resize]);

  return { width, enableResize };
};

export default useResize;