import React from 'react';
import sanitize from 'sanitize-html';
import Typography from '@mui/material/Typography';

const defaultOptions = {
  allowedTags: ['a', 'b', 'i', 'em', 'strong', 'p', 'div', 'br', 'u', 'ul', 'li', 'ol', 'span', 'img', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
  allowedAttributes: {
    'a': ['href', 'target'],
  },
};
export const sanitizeHTML = ({html = ''}, maxLenght = 0) => {
  const sanitized = sanitize(html, defaultOptions);
  const finalText = !maxLenght ?
    sanitized :
    sanitized.includes('\n') ?
      sanitized?.split('\n', 1)[0] :
      sanitized.slice(0, maxLenght) + '...';
  return <Typography variant="caption" dangerouslySetInnerHTML={{__html: finalText}}/>;
};
